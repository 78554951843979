const translations = {
  he: {
    translation: {
      "Home-Title": "בדיקת רכב לפני קנייה",
      "Home-Subtitle": "תאונות, שיעבוד, החלפת בעלויות",
      "Home-Sale": "עכשיו במחיר השקה רק - 79.90 ש״ח",
      "Home-Car number placeholder": "הזינו מספר רישוי",
      "Home-Check button": "בדקו",
      "Checking the seller vehicle": "בדיקת בעלויות לרכב",
      "Official mortgage check": "בדיקת משכונות לרכב",
      "Vehicle history from database": "קורות חיים לרכב",
      "Impairment test and total loss": "בדיקת טוטל לוס",
      "Maintenance costs and red flags": "כללים לבדיקת רכב יד שנייה",
      "Start with": "להתחיל עם זה",
      "Start with info": "רוורס לוקחת אתכם אחורה אל העבר של הרכב אוספת עבורכם מידע ומעניקה לכם את קורות חיי הרכב בדיוק בזמן .הנכון לפני רכישת רכב יד שנייה האם הוא עבר טוטאל לוס .אולי הרכב גנוב או מעוקל ? מה היסטורית הבעלויות שלו ?ייתכן ויש משהו בעבר שלו שמנסים להסתיר .כל מה שצריך לדעת – תוך 5 דקות, ישר למייל שלכם",
      "Check before seen": "לבדוק את האוטו, לפני שראית אותו",
      "Check before seen info": "המערכות שלנו אוספות פרטים מכל מאגרי המידע :הרשמיים לכם נשאר רק להקליק ולקבל תוך דקות את התוצאה בדיוק ברגע שזה רלוונטי לקנייה",
      "Vehicle CV": "קורות חיים של רכב - מה זה אומר",
      "Vehicle CV info": "הכי פשוט שיש: מסמך פשוט, קריא וברור שמגיע ישירות לאימייל שלכם ומפרט את הנתונים השונים שהרשויות יודעות על , הרכב המשומש שאתם שוקלים לקנות: תאונות , שעבודים, משכונים, ירידת ערך, היסטוריית בעלויות .תביעות ביטוח – הכל",
      "Check list": "מה בעצם בודקים פה",
      "Check list info": "רוורס לבעלויות הקודמות | רוורס למשכונים | רוורס להיסטורית הרכב | רוורס לירידת ערך וטוטאל לוס | רוורס לתחזוקה אזהרות וריקול",
      "Guide": "סרטון הדרכה",
      "How report looks": "הכירו את הדוח - איך זה בעצם נראה",
      "Full name": "שם מלא",
      "Full name require": "אנא הזן את השם המלא שלך!",
      "Phone number": "מספר טלפון",
      "Phone number require": "אנא הזן את מספר הטלפון שלך!",
      "Email address": "כתובת אימייל",
      "Email address require": "אנא הזן את האימייל שלך!",
      "Send": "שלח",
      "The commandments": "הדיברות",
      "Report of stolen vehicle": "דיווח על רכב גנוב",
      "Report of a car seller": "דיווח על מוכר רכב",
      "Accessibility": "נגישות",
      "To cancel an order": "לביטול הזמנה",
      "Terms": "תקנון",
      "Privacy Policy": "מדיניות פרטיות",
      "Contact us": "דברו איתנו ברוורס!",
      "Drawer title": "הגדרות",
      "User logged out": "משתמש התנתק",
      "Log In": "התחבר",
      "Logged as": "מחובר כ",
      "Logout": "התנתק",
      "Login account": "התחברות משתמש",
      "LOGIN": 'התחבר',
      "OR REGISTER": "או הירשם",
      "Something went wrong": "משהו השתבש",
      "User logged in": "משתמש התחבר",
      "Email address is not valid": "כתובת האימייל אינה חוקית",
      "Password require": "אנא הזן את הסיסמה שלך!",
      "Password": "סיסמה",
      "Reset password": "איפוס סיסמה",
      "Remember me": "זכור אותי",
      "Password conditions": "מינימום 8 תווים, לפחות אות 1 ומספר 1",
      "Confirm password": "אשר סיסמה",
      "Confirm password require": "אנא הזן את סיסמת האישור שלך!",
      "Passwords do not match": "סיסמאות לא תואמות",
      "SIGNUP": "הירשם",
      "BACK": "חזור",
      "Reset password - Alert description": "בקשה לאיפוס הסיסמה שלך נשלחה לכתובת האימייל שלך. אנא בדוק את תיבת הדואר הנכנס שלך כדי להמשיך בתהליך.",
      "Show report": "הצג דו״ח",
      "Show reports history": "הצג היסטוריית דוחות",
      "Created date": "תאריך יצירה",
      "File": "קובץ",
      "Main page": "עמוד ראשי",
      "Email sent successfully": "אימייל נשלח בהצלחה",
      "Email sent description": "קיבלנו את הפנייה שלך וניצור איתך קשר בהקדם",
      "Owner ID": "מספר תעודת הזהות של בעל הרכב הראשי",
      "Owner date": "תאריך תחילת הבעלות על הרכב (לפי רשיון הרכב)",
      "Report preparation alert": "תודה, הדו״ח שלך בתהליך הכנה ויכול לקחת מספר דקות. בסיום תוכל לצפות בו בעמוד הדוחות שלך.",
      "Search another car": "חפש רכב נוסף",
      "Report demo": "איך נראה הדוח",
      "Order report": "הזמינו דוח",
      'Why-menu': 'למה?',
      'Why-title': 'למה חייבים להיכנס לרוורס?',
      'Why-subtitle': 'המידע שרוורס מעניקה לכם יכול לחסוך קניה מיותרת של רכב פגום. מכאנית או משפטית רוצים לנסוע בראש שקט? כנסו לרוורס',
      'Why-label-1': 'בדיקת רכב מעוקל',
      'Why-info-1': 'בעל חוב אשר עושה כל שניתן כדי לקבל את כספו פותח תיק בהוצאה לפועל ועקב כך נרשם עיקול במשרד התחבורה על הרכב בעל מספר הרישוי הספציפי. מי שמעוניין לבצע העברת בעלות ממוחשבת באחד מסניפי רשות הדואר, אך מסתבר, שהרכב מעוקל, לא יוכל לבצע העברת בעלות',
      'Why-label-2': 'איך פותרים את הבעיה?',
      'Why-info-2': 'אם הרוכש מעוניין ברכב זה, קיימות שתי אופציות לפתרון וזאת רק אם הבעלים הנוכחיים של הרכב, מכיר בחוב ומבין שיש לשלם אותו',
      'Why-info-2-checkmark-1': 'הקונה ישלם את החוב וכך יוסר השעבוד מהרכב. בשלב שני הוא ישלם למוכר הרכב רק את היתרה שנותרה ממחיר הרכב שעליו סוכם בקיזוז ערך החוב ששולם.',
      'Why-info-2-checkmark-2': 'אם המוכר ישלם בעצמו את החוב ורק לאחר שיוכיח שהשעבוד בוטל, אפשר יהיה לבצע העברת בעלות ממוחשבת.',
      'Why-label-3': 'רכב משועבד',
      'Why-info-3': 'בעל רכב שקיבל מימון לצורך רכישת הרכב מפעיל שעבוד על הרכב, כיוון שהרכב משמש בטוחה על החזר כל כספי ההלוואה. לפני שקונים, יש לוודא שאין שיעבוד על הרכב ואת זה. בדיווק רוורס עושה עבורכם',
      'Why-label-4': 'רכב ממושכן ברשם המשכונות',
      'Why-info-4': 'דרך נוספת להעביר בעלות,היא רישום של פרטי רכב ספציפי ברשם המשכונות וכך למנוע את. מכירתו לאדם אחר',
      'Why-label-5': 'הדרך הפשוטה להימנע מסיבוכים',
      'Why-info-5': 'בדיקה קטנה יכולה לחסוך לכם כאב ראש גדול נכנסים לרוורס ומגלים את כל מה שלא סיפרו. לכם על הרכב שאתם עומדים לקנות',
      'Handicapped-menu': 'תו נכה',
      'Handicapped-title': 'תו נכה: לשים לב לפני שקונים',
      'Handicapped-subtitle': 'אתם הרי ממש לא רוצים להגיע למצב שאין באפשרותכם להעביר בעלות, נכון? אז כדי למנוע, סיבוכים מיותרים, יש לדאוג לבטל את תו הנכה(במידה וקיים). בטרם מכירת הרכב הנה כל מה שצריך לדעת כדי לעשות את זה נכון',
      'Handicapped-label-1': 'מה הוא תו נכה?',
      'Handicapped-info-1': 'תו נכה הוא רישיון לחנות במקומות חניה השמוריםהלנכים בלבד – מוכר על ידי הגורמים הרשמיים של. מדינת ישראל בעל רכב שעמד בקריטריונים וקיבל ממשרד הרישוי, תעודה של נכה אשר מקלה על חיי היום יום שלו מקבל תג נכה שמודבק על שמשת הרכב שלו והדבר: מופיע ברשומות של משרד הרישוי. נכים וחולים',
      'Handicapped-label-2': 'קונים רכב? לא לשכוח לבדוק את נושא תו הנכה',
      'Handicapped-info-2': 'אחד הדברים שצריך לבדוק כל מי שרוכש רכב יד שנייה הוא אם לרכב זה משוויך תג נכה אשר מאפשר לו לחנות במקומות חנייה מיוחדים היות ומי שרוכב רכב יד שנייה וימשיך להחזיק (אפילו לא ביודעין)',
      'Handicapped-label-3': 'אז מה עושים במשרד הרישוי?',
      'Handicapped-info-3': 'לאור ההטבות וההקלות בנושא חנייה עבור הנכים כאשר מוכרים רכב הנושא תו, משרד התחבורה לא מאפשר להעביר זכות זו של תג נכה לבעלים החדשים של הרכב כיוון שהם אינם מוכרים כנכים על ידי משרד הרישוי לכן, לפני ביצוע העברת הבעלות על בעל הרכב הנוכחי להחזיר למשרד הרישוי את תג הנכה, כדי שהסטטוס של מספר רישוי זה יהיה כמו כל רכב נורמטיבי ולא בעל זכאות ייחודית. רק לאחר. שמבצעים מהלך זה אפשר לבצע העברת בעלות',
      'Handicapped-label-4': 'קונים רכב? תבדקו מראש את נושא התו נכה',
      'Handicapped-info-4': 'מי שקונה רכב ואינו יודע שיש לו תו נכה, כשירצה לבצע העברת בעלות הוא ייתקל בסירוב, זאת, בשל תג הנכה. במקרים האלו הוא יבקש מבעל הרכב לבצע את המהלך הנדרש ורק לאחר מכן יוכל. לבצע את השינוי הנדרש',
      'Curved vehicle-menu': 'רכב מעוקל או משועבד',
      'Curved vehicle-title': 'רכב מעוקל או משועבד איך נמנעים מקנייה?',
      'Curved vehicle-subtitle': 'מה עושים כדי לא להגיע למצב שאתם עומדים לקנות רכב ואז מגלים שבעצם הבעלים הם לא לגמרי הבעלים? ניחשתם נכון: נכנסים לרוורס',
      'Curved vehicle-label-1': 'הצד המשפטי של הרכב הבא שלכם',
      'Curved vehicle-info-1': 'אדם שמעוניין לרכוש רכב יד שנייה, חייב לבדוק את מצבו של הרכב מבחינה מכנית, חשמלית וגם את הפאן המשפטי. יש מקרים שבהם הרכב מעוקל או משועבד ולכן יש להיות ערניים כדי לא להיקלע לצרה.',
      'Curved vehicle-label-2': 'רוורס פותרת את הבעיה',
      'Curved vehicle-info-2': 'המידע שמקבלים מקורות החיים של הרכב מרוורס מאפשר לדעת האם הרכב מעוקל או משועבד וכמו כן הרבה דברים אחרים שחשוב לבדוק ולדעת.',
      'Curved vehicle-label-3': 'העברת בעלות של הרכב',
      'Curved vehicle-info-3': 'מצאת רכב שנראה לך מתאים לדרישותיך? מצויין. עכשיו, כדאי לבדוק שגם המצב המשפטי שלו ראוי לעסקה',
      'Curved vehicle-label-4': 'שעבוד של רכב',
      'Curved vehicle-info-4': 'אדם שחייב כספים לגורמים שונים כמו ספקים, רשויות המס, או כל גורם אחר וקיים חשש שהוא לא יעמוד בהתחייבויות הכספיות שלו, בעל החוב פותח לו תיק בהוצאה לפועל. בדרך זו הוא מעקל כספים, נכסים ורכבים אשר קיימים ברשותו של אדם זה. המטרה היא לממש את הנכסים הללו, או חלק מהם כדי לקבל בדרך זו את התשלום אשר מגיע לגורם זה.',
      'Curved vehicle-label-5': 'משכון של רכב',
      'Curved vehicle-info-5': 'יש גורמים שמבצעים משכון לרכב כאן ברוורס, ניתן לדעת אם יש משכון ולמי',
      'Recall-menu': 'צו ריקול',
      'Recall-title': 'צו ריקול? יש חלק פסול!',
      'Recall-subtitle': 'ריקול הוא מצב שבו יצרנית רכב מכריזה על כך שיש חלק עם פגם בייצור ויש להחליפו בכל הרכבים. כשקונים רכב משומש יש לשים לב שהרכב שאתם קונים ביצע ריקול במידה והיה צריך',
      'Recall-label-1': 'כשיצרנית מלה פגם בייצור והרכבים כבר על הכביש',
      'Recall-info-1': 'יצרני הרכב בכל העולם מקדישים מחשבה רבה לכל נושא הבטיחות ברכב ולכן הם בוחנים את כל תהליכי התיקון והבדיקה, אשר קור מהרגע שהרכב נמכר וזאת כיוון שהרכבים מגיעים לטיפולים ולבדיקות שוטפות במוסכים בהתאם להוראות של יצרן הרכב.',
      'Recall-label-2': 'קריאת ריקול לרכבים',
      'Recall-info-2': 'אם קיימת תקלה שהיא חד פעמית או תקלה שחוזרת על עצמה שוב ושוב, מהנדסי הרכב בודקים זאת באופן מעמיק ולא אחת הם מגיעים למסקנה שיש בעיה טכנית בחלקים מסוימים ולכן יש להחליפם כדי להגן על הנהגים והנוסעים ולהעניק להם בטיחות מלאה, יצרני הרכב מייצרים את החלף החדש שיהיה מתאים יותר מהחלק המקורי. השלב הבא הוא קריאה לכל בעלי הרכב בדגם הספציפי על פי שנת הייצור להגיע לאחד המוסמכים המורשים כדי לבצע את ההחלפה. כל מי שרכש רכב מסוג זה, מקבל על כך הודעה באחד מאמצעי התקשורת ובהתאם לכך הוא מתאם תור למוסך מורשה כדי לבצע את ההחלפה בפועל.',
      'Recall-label-3': 'מחויבות לביצוע ריקול בפועל',
      'Recall-info-3': 'לאור החשיבות הרבה של ביצוע הריקול, כדי למנוע מצבים שהם לא בטיחותיים, כל בעל רכב מחויב לבצע פעולה זו מוקדם ככל האפשר',
      'Recall-label-4': 'ריקול, תנאי הכרחי כדי לעבור את הטסט',
      'Recall-info-4': 'בכל שנה כל רכב עובר מבחן רישוי שנתי כדי לבדוק את תקינותו. בעל רכב שמגיע למכון הרישוי ועל פי הבדיקה יסתבר שהוא לא פעל כנדרש ולא בוצעה החלפה של החלק הספציפי על פי הוראת היצרן, לא יוכל לעבור את מבחן הטסט השנתי רק לאחר שהוא יבצע את הפעולה הנדרשת, הוא יגיע שוב למכון הרישוי ואם הכול תקין, הוא יעבור את הטסט ויוכל להמשיך לנסוע ברכב',
      'Certified garage-menu': 'מוסך מורשה',
      'Certified garage-title': 'מוסך מורשה: מדוע הוא הכרחי?',
      'Certified garage-subtitle': 'משרד התחבורה מעניק רישיון ופיקוח בשבילנו ציבור הנהגים שרוצה לדעת שהרכב שלנו בידיים טובות',
      'Certified garage-label-1': 'מה זה מוסך?',
      'Certified garage-info-1': 'מוסכים מורשים מטעם משרד התחבורה הם מוסכים שנמצאים בפיקוח של משרד התחבורה והם בדרך כלל חברים באיגוד המוסכים',
      'Certified garage-label-2': 'מה הסטנדרט אליו מחויבים המוסכים המורשים?',
      'Certified garage-info-2': 'במוסך מורשה משרד התחבורה מעניקים טיפול מקצועי לרכבים מכל הסוגים ושל כל יצרני הרכב אשר הרכבים המיובאים ארצה. המוסכים הללו מחויבים לפעול על פי תקנות מחמירות. עובדי מוסך עוברים הכשרות והדרכות מקצועיות בכל הקשור לאופן הבדיקה, התיקון ותחזוקה של כל אחד מהרכבים של היצרנים השונים. בראש המוסך מחויב לעמוד מכונאי בעל הכשרות ספציפיות, המנהל את המערך המקצועי',
      'Certified garage-label-3': 'שקיפות: ככה עובד מוסך מורשה',
      'Certified garage-info-3': 'במוסך מסוג זה חייב להיות תלוי מחירון במקום גלוי לעין. שם מפורטות העלויות של שעת העבודה, ושל העלות של החלפים המקוריים. עובדי המוסך שמקבלים רכב לטיפול, מחויבים לאחר הבדיקה ליידע את הלקוח מהם הממצאים שלהם, מה יש לתקן ומהן העלויות של התיקון, החלפים ושעות העבודה. רק לאחר אישורו של הלקוח לביצוע העבודה, צוות העובדים מבצע זאת בפועל',
      'Certified garage-label-4': 'תיעוד של הפעולות במוסך מורשה',
      'Certified garage-info-4': 'במוסך מורשה, כל הנתונים על התיקונים השונים שבוצעו ברכב, אילו חלפים הוחלפו, מתי בוצעו הטיפולים השוטפים וכל מידע אחר, מפורטים בתוכנה הממוחשבת. כך אפשר לבדוק לדוגמה מתי הוחלפו הבלמים, רצועת הטיימינג וכו’. מידע זה חשוב לכל בעל רכב ובמיוחד בעת רכישת רכב יד שנייה וכך הוא רואה את מלוא האינפורמציה באופן מרוכז',
      'Certified garage-label-5': 'מוסכי מדרכה – איך מזהים ונזהרים?',
      'Certified garage-info-5': 'מוסכי מדרכה הם מוסכים פיראטיים אשר פועלים ללא פיקוח. לכן לא אחת הנהגים לא מרוצים מהטיפולים שבוצעו ברכב כיוון שאין למי לפנות במקרה של בעיה ואין עליהם פיקוח של משרד התחבורה. המחירים במוסכים הללו הם גבוהים ולא תמיד מותקנים ברכבים המגיעים לתיקון חלפים מקוריים או חדשים. יש חלפים משומשים שמותקנים ברכבים שהם מחזיקים מעמד פרק זמן קצר, או שהם לא תקינים לאור כל זאת ההמלצה היא לא לתקן את הרכבים במוסכי מדרכה',
      'Car dealer-menu': 'סוחר רכב מורשה',
      'Car dealer-title': 'סוחר רכב מורשה: למה הוא חשוב בעצם?',
      'Car dealer-subtitle': 'רוצים לקנות רכב בלי להסתכן בעסקה מפוקפקת? קונים מסוחר רכב מורשה המחזיק ברישיון ממשרד התחבורה ונוסעים בראש שקט',
      'Car dealer-label-1': 'מהו סוחר רכב מורשה?',
      'Car dealer-info-1': 'סוחר רכב מורשה הוא בעל מקצוע שקיבל אישור על כך שהעסק שלו בתחום המסחר במכוניות הוא מפוקח ולכן הוא מאושר לתפעול על ידי משרד התחבורה.',
      'Car dealer-label-2': 'היתרונות לקניה מסוחר רכב',
      'Car dealer-info-2-checkmark-1': 'קנייה ממקום עם אחריות',
      'Car dealer-info-2-checkmark-2': 'קנייה ממקום אשר דואג למוניטין שלו',
      'Car dealer-info-2-checkmark-3': 'קנייה מבעל מקצוע אשר אמון לדעת ולהבין את מאפייני הרכב ואחראי עליהם',
      'Car dealer-info-2-checkmark-4': 'קבלת רכב לאחר השבחה',
      'Car dealer-label-3': 'מטפלים ברכב ומוכרים אותו',
      'Car dealer-info-3': 'המגמה של כל אחד מסוחרי הרכב היא לרכוש רכבים שונים בעלות שהיא נמוכה ממחיר המחירון ולאחר מכן הם מעבירים את הרכב לטיפול של אנשי מקצוע שהם עובדים איתם בשיתוף פעולה כמו חשמלאי רכב, מכונאי רכב, איש פחחות, איש צבע ועוד בהתאם למצבו שלה הרכב הוא מזמין מהם את ביצוע הטיפולים הנדרשים כדי שאפשר יהיה למכור רכב תקין, כמו לדוגמה צבע ותיקוני פחחות. לאחר מכן כל רכב עובר ניקיון יסודי פנימי וחיצוני וכך הוא מוכן למכירה בשלב הבא, מפרסם את הרכב למכירה במדיות השונות.',
      'Car dealer-label-4': 'קנייה של רכבים משומשים',
      'Car dealer-info-4': 'סוחרי הרכב קונים רכבים במכרזים, מגרשים של רכבי טרייד-אין ומאנשים פרטיים. הסוחרים מחזיקים בתו סחר שמאפשר להם לטפל ברכב עד מכירתו ללא צורך בהעברת בעלות וכך לא נרשמת על הרכב יד נוספת. בנוסף לכך, תו זה מאפשר להקפיא את מועד הטסט על מכירת הרכב והבעלים החדשים דואג לתשלום הטסט ולהעברת הרכב במבחן השנתי לגבי תקינותו.',
      'How it works-menu': 'איך זה עובד?',
      'How it works-title': 'איך זה בעצם עובד?',
      'How it works-subtitle': 'שלושה שלבים ויש לכם קורות חיים של רכב עם נתונים ותובנות לצאת לדרך',
      'How it works-label-1': 'חלק ראשון: רוורס 7 שנים אחורה',
      'How it works-info-1': 'מציידים אתכם במידע על הנקודות הרגישות ביותר בקניית רכב משומש',
      'How it works-info-1-checkmark-1': 'מעורבות בתאונות?',
      'How it works-info-1-checkmark-2': 'ירידת ערך עקב תאונות?',
      'How it works-info-1-checkmark-3': 'האם בעבר הרכב נגנב?',
      'How it works-info-1-checkmark-4': 'האם הרכב היה מבוטח?',
      'How it works-info-1-checkmark-5': '?האם הרכב הוכרז כטוטאל לוס',
      'How it works-info-1-checkmark-6': '?האם שולמו פיצויים מחברות הביטוח',
      'How it works-label-2': 'שלב שני: בעלויות קודמות',
      'How it works-info-2': 'מי שמוכר רכב יד שלישית אולי לא יודע הכל על העבר של הרכ',
      'How it works-info-2-checkmark-1': '?האם הרכב היה בבעלות גורם מסחרי בעבר',
      'How it works-info-2-checkmark-2': '?אולי יש סיבה לחשוד באחת הבעלויות הקודמות',
      'How it works-label-3': 'שלב שלישי: מסקנות וכלים לעסקה נכונה',
      'How it works-info-3': 'מציידים אתכם במידע על הנקודות הרגישות ביותר בקניית רכב משומש',
      'How it works-info-3-checkmark-1': 'האם ישנם נתונים בעייתיים שכדאי לשים לב אליהם?',
      'How it works-info-3-checkmark-2': 'האם דווקא הרכב הזה עושה רושם טוב?',
      'How it works-info-3-checkmark-3': '?מה הבינה המלאכותית שלנו אומרת על ההזדמנות',
      'How it works-info-4': 'מערכת הבינה המלאכותית מסייעת לכם עם תובנות, סיכומים ומסקנות, בעצם חושבת ביחד אתכם: מה הצעד שהכי נכון לעשות עכשיו',
      'How it works-info-4-checkmark-1': '?בכמה כדאי לקנות',
      'How it works-info-4-checkmark-2': '?ירידת ערך – מה אומרת התחזית שלנו',
      'How it works-info-4-checkmark-3': '?כמה יעלה לתחזק את הרכב הזה',
      'How it works-info-4-checkmark-4': 'טפסי מכירה',
      'How it works-info-4-checkmark-5': '?הנחיות וטיפים: כיצד יש לנהוג במכירה או קנייה',
      'Total loss-menu': 'טוטאל לוס',
      'Total loss-title': 'טוטאל לוס? איך לא קונים רכב כזה',
      'Total loss-subtitle': 'טוטאל לוס הוא מצב שבו רכב מורד מהכביש לאחר שהנזק שנגרם מתאונה שווה ל60% ומעלה מערכו. מה התהליך שמאחורי הכרזה על רכב כזה? כל המידע לפניכם',
      'Total loss-label-1': 'שמאי רכב – איש המקצוע בדיוק למצבים האלה',
      'Total loss-info-1': 'תו נכה הוא רישיון לחנות במקומות חניה השמוריםהלנכים בלבד – מוכר על ידי הגורמים הרשמיים של. מדינת ישראל בעל רכב שעמד בקריטריונים וקיבל ממשרד הרישוי, תעודה של נכה אשר מקלה על חיי היום יום שלו מקבל תג נכה שמודבק על שמשת הרכב שלו והדבר: מופיע ברשומות של משרד הרישוי. נכים וחולים',
      'Total loss-label-2': 'טוטאל לוס – מה זה?',
      'Total loss-info-2': 'טוטאל לוס הוא מצב בו אין כדאיות כלכלית לתקן את הרכב במצב זה, לכן השמאי מוריד אותו מהכביש. להוראה זו של השמאי יש כמה השלכות',
      'Total loss-info-2-checkmark-1': 'כיוון שהרכב במצב זה בדרך כלל אינו מסוגל לנוע בכבישים, גרר של מגרש הגרוטאות גורר אותו למגרש כדי לבצע פעולות מקיפות של פירוק. חלק מהחלפים מועברים למחזור וחלפים שהם תקינים מועברים למכירה כחלקים משומשים אך תקינים',
      'Total loss-info-2-checkmark-2': 'על הנהג לדווח למשרד הרישוי שהרכב פורק ולכן יש להוריד אותו מרישום הרכבים שנעים בכבישי הארץ',
      'Total loss-info-2-checkmark-3': 'חברת הביטוח מעבירה תשלום לבעל הרכב על פי ערכו המלא, בהתאם לקביעה של השמאי',
      'Total loss-label-3': 'אובדן חלקי של ערך הרכב',
      'Total loss-info-3': 'לאחר תאונה קלה יחסית, שמאי הרכב מאשר לאנשי המוסך לבצע תיקונים ספציפיים אשר ישולמו על ידי חברת הביטוח של הנהג הפוגע עם זאת התיקונים הללו יוצרים ירידה של אחוזים מסוימים בערך הרכב. לכל חלק של הרכב יש אחוז מסוים כמו לדוגמה',
      'Total loss-info-3-example-1': 'כנף אחורית - X%',
      'Total loss-info-3-example-2': 'קורת שלדה - Y%',
      'Total loss-info-3-example-3': 'פגיעה בפח - Z%',
      'Total loss-label-4': 'הביטוח תמיד בתמונה',
      'Total loss-info-4': 'חברת הביטוח משלמת לבעל הרכב את סך ירידת הערך כיוון שנפגע במהלך תאונה שלא הייתה באשמתו ובעת מכירת הרכב יקבל סכום נמוך יותר מהשווי של הרכב עקב כך',
      'Rules-menu': 'כללים',
      'Rules-title': 'כללים לקניית רכב יד שניה',
      'Rules-subtitle': 'מה חייבים לעשות לפני שקונים רכב משומש',
      'Rules-label-1': 'מדברים עם בעל הרכב / הנהג',
      'Rules-info-1': 'בשלב הזה חשוב מאוד לבקש תמונות עדכניות של הרכב ולשאול מהו הקילומטראז’, מה בעלויות קודמות, היסטורית תקלות וטיפולים',
      'Rules-label-2': 'לבדוק את הרכב ברוורס',
      'Rules-info-2': 'עם רוורס אפשר להכיר את הרכב שאתם שוקלים לקנות כבר מרחוק עוד לפני שטרחתם ונסעתם לבדוק את מצבו ולפגוש את בעליו',
      'Rules-label-3': 'לבדוק את הרכב ברוורס',
      'Rules-info-3': 'עם רוורס אפשר להכיר את הרכב שאתם שוקלים לקנות כבר מרחוק עוד לפני שטרחתם ונסעתם לבדוק את מצבו ולפגוש את בעליו',
      'Rules-label-4': 'נסיעת מבחן ובדיקה ויזואלית חיצונית',
      'Rules-info-4': 'בחנו את האוטו מבחוץ פתחו את מכסה המנוע לחפש חיבורים רופפים, סימני נזילות, תוקף מצבר, וקח את הרכב לנסיעת מבחן, חשוב מאוד לסגור את הרדיו לפתוח חלונות ולהקשיב אם יש רעשים חריגים, לבדוק שהמזגן תקין',
      'Rules-label-5': 'וידוא פרטים',
      'Rules-info-5': 'יש לוודא שהפרטים תואמים והבעלים הם אכן הבעלים לפי רישיון הרכב ותעודת הזהות של המוכר',
      'Rules-info-5-important-1': 'קראו את קורות החיים של הרכב ברוורס האם לרכב היו תאונות עם ירידת ערך ואת מספר הקילומטרים האחרון שדווח זה הזמן לשקול – עסקה הוגנת או לא',
      'Rules-info-5-important-2': 'חשוב מאוד!',
      'Rules-label-6': 'לא קנית? הדו”ח שלך שווה כסף',
      'Rules-info-6': 'במידה והרכב לא מתאים לצרכים שלך זה המקום להעלות את הדוח קניה לרכב (הרי גם ככה לא תשתמש בו)',
      'Rules-info-6-upload-info': 'העלאה של הדוח תקנה לך 25% הנחה לדוח רוורס הבא',
      'Rules-info-6-upload-button': 'ניתן להעלות את הקובץ כאן',
      'Rules-label-7': 'הסכמים? יש לנו כבר נוסח מוכן',
      'Rules-info-7-upload-info': 'מסמך גילוי נאות וטופס אחרי בדיקה/זיכרון דברים',
      'Rules-info-7-upload-button': 'מורידים מהאתר וחותמים',
      'Rules-label-8': 'יוצאים לדרך חדשה, שיהיה בהצלחה!',
      'Car number': 'מספר רכב',
      "Choose Language": "בחר שפה",
    }
  },
  en: {
    translation: {
      "Home-Title": "Vehicle Inspection Before Purchase",
      "Home-Subtitle": "Accidents, Liens, Ownership Transfers",
      "Home-Sale": "Now at a launch price of only - 79.90 ILS",
      "Home-Car number placeholder": "Enter license plate number",
      "Home-Check button": "Check",
      "Checking the seller vehicle": "Vehicle Ownership Check",
      "Official mortgage check": "Official Vehicle Lien Check",
      "Vehicle history from database": "Vehicle History Report",
      "Impairment test and total loss": "Total Loss and Depreciation Check",
      "Maintenance costs and red flags": "Secondhand Vehicle Maintenance Tips",
      "Start with": "Start with it",
      "Start with info": "Reverse takes you back into the vehicle's history, gathers information for you, and provides the car's CV right on time, before purchasing a secondhand vehicle. Has it been totaled? Maybe it's stolen or has a lien? What's its ownership history? There might be something in its past being hidden. Everything you need to know – within 5 minutes, straight to your email.",
      "Check before seen": "Check the car before you see it",
      "Check before seen info": "Our systems collect details from all official databases. All you need to do is click and receive the results within minutes, just when it's relevant for your purchase.",
      "Vehicle CV": "Vehicle CV – What does it mean?",
      "Vehicle CV info": "It's simple: a clear, readable document sent directly to your email, detailing various data known to authorities about the used vehicle you're considering buying: accidents, liens, mortgages, depreciation, ownership history, insurance claims – everything.",
      "Check list": "What exactly is being checked here",
      "Check list info": "Reverse to previous ownerships | Reverse to liens | Reverse to vehicle history | Reverse to depreciation and total loss | Reverse to maintenance, warnings, and recalls",
      "Guide": "Instructional Video",
      "How report looks": "See the Report – What does it look like?",
      "Full name": "Full Name",
      "Full name require": "Please enter your full name!",
      "Phone number": "Phone Number",
      "Phone number require": "Please enter your phone number!",
      "Email address": "Email Address",
      "Email address require": "Please enter your email address!",
      "Send": "Send",
      "The commandments": "The Commandments",
      "Report of stolen vehicle": "Report a Stolen Vehicle",
      "Report of a car seller": "Report a Vehicle Seller",
      "Accessibility": "Accessibility",
      "To cancel an order": "Cancel an Order",
      "Terms": "Terms and Conditions",
      "Privacy Policy": "Privacy Policy",
      "Contact us": "Contact Reverse!",
      "Drawer title": "Settings",
      "User logged out": "User Logged Out",
      "Log In": "Log In",
      "Logged as": "Logged in as",
      "Logout": "Log Out",
      "Login account": "User Login",
      "LOGIN": "Log In",
      "OR REGISTER": "Or Register",
      "Something went wrong": "Something Went Wrong",
      "User logged in": "User Logged In",
      "Email address is not valid": "Email address is not valid",
      "Password require": "Please enter your password!",
      "Password": "Password",
      "Reset password": "Reset Password",
      "Remember me": "Remember Me",
      "Password conditions": "Minimum 8 characters, at least 1 letter and 1 number",
      "Confirm password": "Confirm Password",
      "Confirm password require": "Please confirm your password!",
      "Passwords do not match": "Passwords do not match",
      "SIGNUP": "Sign Up",
      "BACK": "Back",
      "Reset password - Alert description": "A password reset request has been sent to your email address. Please check your inbox to continue the process.",
      "Show report": "Show Report",
      "Show reports history": "Show Report History",
      "Created date": "Created Date",
      "File": "File",
      "Main page": "Main Page",
      "Email sent successfully": "Email Sent Successfully",
      "Email sent description": "We received your inquiry and will contact you shortly.",
      "Owner ID": "Main Vehicle Owner's ID Number",
      "Owner date": "Start Date of Vehicle Ownership (according to registration)",
      "Report preparation alert": "Thank you, your report is being prepared and may take a few minutes. Once ready, you can view it on your reports page.",
      "Search another car": "Search for Another Vehicle",
      "Report demo": "What the Report Looks Like",
      "Order report": "Order Report",
      "Why-menu": "Why?",
      "Why-title": "Why Choose Reverse?",
      "Why-subtitle": "The information provided by Reverse can save you from purchasing a faulty vehicle – whether mechanically or legally. Want peace of mind? Use Reverse.",
      "Why-label-1": "Seized Vehicle Inspection",
      "Why-info-1": "A creditor who takes all necessary steps to recover their money opens a case with the Enforcement and Collection Authority, resulting in a seizure being registered by the Ministry of Transport on the specific vehicle's license plate. Anyone attempting to transfer ownership digitally at one of the Postal Authority branches and discovering that the vehicle is seized will not be able to complete the ownership transfer.",
      "Why-label-2": "How to Solve the Problem?",
      "Why-info-2": "If the buyer is interested in this vehicle, there are two possible solutions, provided the current owner acknowledges the debt and understands the need to pay it.",
      "Why-info-2-checkmark-1": "The buyer pays the debt, thereby lifting the lien from the vehicle. They then pay the seller the remaining balance of the agreed-upon vehicle price, minus the debt amount already paid.",
      "Why-info-2-checkmark-2": "If the seller pays the debt themselves, they must prove that the lien has been removed before a digital ownership transfer can take place.",
      "Why-label-3": "Encumbered Vehicle",
      "Why-info-3": "A vehicle owner who received financing for purchasing the vehicle has the vehicle encumbered as collateral for repaying the loan. Before buying, ensure there is no encumbrance on the vehicle. Reverse does this check for you.",
      "Why-label-4": "Mortgaged Vehicle in the Registrar of Pledges",
      "Why-info-4": "Another way to block ownership transfer is to register specific vehicle details with the Registrar of Pledges, thereby preventing its sale to another party.",
      "Why-label-5": "The Simple Way to Avoid Complications",
      "Why-info-5": "A small check can save you a big headache. Use Reverse to uncover everything not disclosed about the car you're planning to buy.",
      "Handicapped-menu": "Disabled Parking Permit",
      "Handicapped-title": "Disabled Parking Permit: What to Know Before Buying",
      "Handicapped-subtitle": "You certainly don’t want to find yourself unable to transfer ownership. To avoid unnecessary complications, ensure the disabled parking permit (if one exists) is canceled before selling the vehicle. Here's everything you need to know to do it right.",
      "Handicapped-label-1": "What is a Disabled Parking Permit?",
      "Handicapped-info-1": "A disabled parking permit is a license allowing parking in spaces reserved exclusively for the disabled. Officially recognized in Israel, the permit is issued to vehicle owners meeting specific criteria set by the Licensing Ministry. It eases daily life for permit holders, appearing on the vehicle’s windshield and registered in the Licensing Ministry's records.",
      "Handicapped-label-2": "Buying a Car? Don’t Forget to Check for a Disabled Permit",
      "Handicapped-info-2": "One essential check for anyone purchasing a second-hand car is whether the vehicle has an associated disabled parking permit, which allows parking in designated spaces. Buyers who unknowingly keep such a permit may face future issues.",
      "Handicapped-label-3": "What to Do at the Licensing Office?",
      "Handicapped-info-3": "Due to the benefits and privileges related to disabled parking, the Ministry of Transport does not allow transferring this benefit to a new vehicle owner unless they meet the criteria. Before ownership transfer, the current owner must return the disabled parking permit to the Licensing Office, ensuring the license plate status aligns with standard vehicles. Only after this step can ownership transfer proceed.",
      "Handicapped-label-4": "Buying a Car? Check for a Disabled Permit in Advance",
      "Handicapped-info-4": "Buyers unaware of a disabled parking permit linked to the car will face rejection when attempting to transfer ownership due to the permit. In such cases, they must request the previous owner to cancel the permit before the transfer can occur.",
      "Curved vehicle-menu": "Seized or Encumbered Vehicle",
      "Curved vehicle-title": "Seized or Encumbered Vehicle: How to Avoid Buying One?",
      "Curved vehicle-subtitle": "What should you do to avoid discovering the seller isn’t the true owner? You guessed it: use Reverse.",
      "Curved vehicle-label-1": "The Legal Side of Your Next Vehicle",
      "Curved vehicle-info-1": "Anyone looking to purchase a second-hand vehicle must check its mechanical, electrical, and legal condition. In some cases, the vehicle is seized or encumbered, making it vital to stay vigilant and avoid trouble.",
      "Curved vehicle-label-2": "Reverse Solves the Problem",
      "Curved vehicle-info-2": "The vehicle history information provided by Reverse lets you determine whether the car is seized, encumbered, or has other critical issues to consider.",
      "Curved vehicle-label-3": "Transferring Vehicle Ownership",
      "Curved vehicle-info-3": "Found a vehicle that meets your requirements? Great. Now, ensure its legal status is clear for the transaction.",
      "Curved vehicle-label-4": "Vehicle Encumbrance",
      "Curved vehicle-info-4": "A person who owes money to entities such as suppliers, tax authorities, or others may have their assets, including vehicles, seized to secure debts. This allows creditors to liquidate assets to recover the owed amounts.",
      "Curved vehicle-label-5": "Vehicle Mortgage",
      "Curved vehicle-info-5": "Some entities place mortgages on vehicles. With Reverse, you can check for existing mortgages and identify the mortgage holder.",
      "Recall-menu": "Recall Order",
      "Recall-title": "Recall Order? There's a Faulty Part!",
      "Recall-subtitle": "A recall is when a car manufacturer announces that there is a manufacturing defect in a part that needs to be replaced in all vehicles. When buying a used car, you must check whether the car you are buying has undergone a recall if it was required.",
      "Recall-label-1": "When a manufacturer finds a defect in production and the cars are already on the road",
      "Recall-info-1": "Car manufacturers worldwide dedicate great thought to safety issues, which is why they monitor all repair and inspection processes. This continues from the moment the car is sold, as vehicles undergo regular maintenance and inspections at authorized garages, following the manufacturer's guidelines.",
      "Recall-label-2": "Vehicle Recall Announcement",
      "Recall-info-2": "If there is a one-time defect or a recurring issue, car engineers will investigate it thoroughly and often conclude that a specific part is defective and needs replacing in order to ensure the safety of drivers and passengers. The manufacturer will then produce a new part, which will be more suitable than the original. The next step is for all owners of the specific model, based on the year of manufacture, to be contacted and asked to visit an authorized garage for the replacement. Anyone who purchased such a vehicle will be notified via communication channels and can schedule an appointment at an authorized garage to carry out the replacement.",
      "Recall-label-3": "Commitment to Perform the Recall",
      "Recall-info-3": "Due to the high importance of performing the recall to avoid unsafe situations, every vehicle owner is obligated to act on this as soon as possible.",
      "Recall-label-4": "Recall, a Mandatory Condition to Pass the Inspection",
      "Recall-info-4": "Every year, every vehicle undergoes an annual licensing test to check its condition. If a car owner arrives at the licensing center and it is found that they have not complied with the manufacturer’s recall instructions by replacing the specific part, they will not pass the annual test. Only after completing the required actions and confirming everything is in order will they be able to pass the test and continue driving the vehicle.",
      "Certified garage-menu": "Authorized Garage",
      "Certified garage-title": "Authorized Garage: Why is it Necessary?",
      "Certified garage-subtitle": "The Ministry of Transport provides licensing and supervision for the public, so drivers can be confident that their vehicle is in good hands.",
      "Certified garage-label-1": "What is an Authorized Garage?",
      "Certified garage-info-1": "Authorized garages licensed by the Ministry of Transport are garages that operate under the supervision of the Ministry and are typically members of the mechanics' association.",
      "Certified garage-label-2": "What Standard are Authorized Garages Required to Follow?",
      "Certified garage-info-2": "In an authorized garage, the Ministry of Transport provides professional care for vehicles of all types and manufacturers. These garages are required to operate according to strict regulations. Garage employees undergo training and certification on the proper methods for inspecting, repairing, and maintaining vehicles from various manufacturers. The garage must be managed by a certified mechanic who oversees the professional processes.",
      "Certified garage-label-3": "Transparency: How an Authorized Garage Operates",
      "Certified garage-info-3": "An authorized garage must display a price list in a visible location, detailing the cost of labor and original parts. When the garage receives a vehicle for service, the workers are required to inform the customer of the findings, what needs to be fixed, and the cost of repairs, parts, and labor. Work will only be carried out once the customer approves.",
      "Certified garage-label-4": "Documentation of Actions in an Authorized Garage",
      "Certified garage-info-4": "In an authorized garage, all data about repairs, parts replaced, and maintenance performed is recorded in a computerized system. This allows vehicle owners, especially when buying a used car, to access comprehensive information about repairs, such as when the brakes or timing belt were replaced.",
      "Certified garage-label-5": "Street Garages – How to Identify and Avoid Them?",
      "Certified garage-info-5": "Street garages are unlicensed and operate without supervision. Often, drivers are dissatisfied with the services because there's no one to turn to in case of problems, and there is no oversight from the Ministry of Transport. Prices at these garages are high, and the parts installed are not always original or new. They may use second-hand parts that break quickly or are defective. It is recommended not to repair vehicles at street garages.",
      "Car dealer-menu": "Licensed Car Dealer",
      "Car dealer-title": "Licensed Car Dealer: Why is it Important?",
      "Car dealer-subtitle": "Want to buy a car without risking a shady deal? Buy from a licensed car dealer who holds a permit from the Ministry of Transport and drive with peace of mind.",
      "Car dealer-label-1": "What is a Licensed Car Dealer?",
      "Car dealer-info-1": "A licensed car dealer is a professional who has been authorized by the Ministry of Transport to operate a business in the car trading industry, ensuring that their business is regulated.",
      "Car dealer-label-2": "Advantages of Buying from a Car Dealer",
      "Car dealer-info-2-checkmark-1": "Purchase from a place with warranty",
      "Car dealer-info-2-checkmark-2": "Purchase from a place that cares about its reputation",
      "Car dealer-info-2-checkmark-3": "Purchase from a professional who knows and understands vehicle features and is responsible for them",
      "Car dealer-info-2-checkmark-4": "Receive a car after refurbishment",
      "Car dealer-label-3": "They Repair the Car and Sell It",
      "Car dealer-info-3": "Car dealers typically buy cars for a price lower than the market value and then send them for repairs to professionals they collaborate with, such as electricians, mechanics, bodywork specialists, and painters, depending on the condition of the car. After necessary repairs, such as paint jobs and bodywork fixes, the car is thoroughly cleaned, both inside and out, and prepared for sale. The dealer will then advertise the car for sale through various media.",
      "Car dealer-label-4": "Buying Used Cars",
      "Car dealer-info-4": "Car dealers buy vehicles from auctions, trade-in lots, and private individuals. They hold a trade license, which allows them to operate on the vehicle until it is sold without transferring ownership, meaning no additional ownership record is made. This license also allows them to freeze the test date for the vehicle, and the new owner will be responsible for paying the test fee and transferring the vehicle for its annual inspection.",
      "How it works-menu": "How It Works?",
      "How it works-title": "How Does It Work?",
      "How it works-subtitle": "Three steps and you'll have a car's history with data and insights to hit the road.",
      "How it works-label-1": "First Step: Reverse 7 Years Back",
      "How it works-info-1": "We equip you with information about the most sensitive points when buying a used car.",
      "How it works-info-1-checkmark-1": "Involved in accidents?",
      "How it works-info-1-checkmark-2": "Depreciation due to accidents?",
      "How it works-info-1-checkmark-3": "Has the car been stolen in the past?",
      "How it works-info-1-checkmark-4": "Was the car insured?",
      "How it works-info-1-checkmark-5": "Was the car declared a total loss?",
      "How it works-info-1-checkmark-6": "Have insurance claims been paid?",
      "How it works-label-2": "Step Two: Previous Ownerships",
      "How it works-info-2": "Someone selling a third-hand car may not know everything about the car's past.",
      "How it works-info-2-checkmark-1": "Was the car previously owned by a commercial entity?",
      "How it works-info-2-checkmark-2": "Is there a reason to suspect any of the previous owners?",
      "How it works-label-3": "Step Three: Conclusions and Tools for a Correct Deal",
      "How it works-info-3": "We equip you with information about the most sensitive points when buying a used car.",
      "How it works-info-3-checkmark-1": "Are there problematic data points you should be aware of?",
      "How it works-info-3-checkmark-2": "Does this car give a good impression?",
      "How it works-info-3-checkmark-3": "What does our AI say about the opportunity?",
      "How it works-info-4": "Our AI system assists you with insights, summaries, and conclusions, essentially thinking together with you: what is the best step to take now?",
      "How it works-info-4-checkmark-1": "How much should you pay?",
      "How it works-info-4-checkmark-2": "Depreciation – what does our forecast say?",
      "How it works-info-4-checkmark-3": "How much will it cost to maintain this car?",
      "How it works-info-4-checkmark-4": "Sales forms",
      "How it works-info-4-checkmark-5": "Guidelines and tips: how to act in a sale or purchase",
      "Total loss-menu": "Total Loss",
      "Total loss-title": "Total Loss? How not to buy such a car",
      "Total loss-subtitle": "Total loss is a situation where a car is taken off the road after the damage caused by an accident equals or exceeds 60% of its value. What is the process behind declaring such a car? All the information is here.",
      "Total loss-label-1": "Car appraiser – the professional for these situations",
      "Total loss-info-1": "A handicapped tag is a license for parking in spaces reserved for the handicapped only – issued by official authorities in Israel. A vehicle owner who meets the criteria and receives a certificate from the licensing office, receives a handicapped tag to facilitate their daily life. This tag is affixed to the windshield of their car and is recorded in the Ministry of Transport's records.",
      "Total loss-label-2": "Total Loss – What is it?",
      "Total loss-info-2": "Total loss is a situation where repairing the car is not economically viable, so the appraiser takes it off the road. This decision has several consequences.",
      "Total loss-info-2-checkmark-1": "Since the car is usually unable to drive on the roads, a tow truck takes it to a junkyard for comprehensive dismantling. Some parts are recycled, and parts that are still functional are sold as used but working parts.",
      "Total loss-info-2-checkmark-2": "The driver must report to the licensing office that the car has been dismantled and therefore needs to be removed from the vehicle registration list.",
      "Total loss-info-2-checkmark-3": "The insurance company pays the car owner its full value based on the appraiser's assessment.",
      "Total loss-label-3": "Partial Loss of Vehicle Value",
      "Total loss-info-3": "After a relatively light accident, the car appraiser authorizes the garage professionals to carry out specific repairs that will be paid for by the insurance company of the at-fault driver. However, these repairs result in a certain percentage loss in the car's value. Each part of the car has a specific percentage loss, such as:",
      "Total loss-info-3-example-1": "Rear wing – X%",
      "Total loss-info-3-example-2": "Chassis beam – Y%",
      "Total loss-info-3-example-3": "Bodywork damage – Z%",
      "Total loss-label-4": "Insurance Is Always Involved",
      "Total loss-info-4": "The insurance company pays the car owner for the value loss since it was caused by an accident that was not their fault, and when the car is sold, it will be worth less due to this.",
      "Rules-menu": "Rules",
      "Rules-title": "Rules for Buying a Used Car",
      "Rules-subtitle": "What you must do before buying a used car",
      "Rules-label-1": "Talk to the owner/driver",
      "Rules-info-1": "At this stage, it's very important to ask for up-to-date pictures of the car and inquire about the mileage, previous ownerships, history of issues and repairs.",
      "Rules-label-2": "Check the car in reverse",
      "Rules-info-2": "With reverse, you can get to know the car you're considering buying from a distance, even before you take the trouble to inspect it in person and meet its owner.",
      "Rules-label-3": "Check the car in reverse",
      "Rules-info-3": "With reverse, you can get to know the car you're considering buying from a distance, even before you take the trouble to inspect it in person and meet its owner.",
      "Rules-label-4": "Test drive and external visual inspection",
      "Rules-info-4": "Examine the car from the outside, open the hood to check for loose connections, leaks, battery condition, and take the car for a test drive. It's very important to turn off the radio, open the windows, and listen for any unusual noises. Check that the air conditioning is working.",
      "Rules-label-5": "Verify details",
      "Rules-info-5": "Make sure the details match and that the owner is indeed the registered owner according to the vehicle registration and the seller's ID card.",
      "Rules-info-5-important-1": "Read the car’s history – Has the car been involved in accidents with depreciation, and what was the last reported mileage? Now is the time to decide if it’s a fair deal or not.",
      "Rules-info-5-important-2": "Very important!",
      "Rules-label-6": "Didn’t buy? Your report is worth money",
      "Rules-info-6": "If the car isn’t suitable for your needs, this is the place to upload the purchase report (since you won’t use it anyway).",
      "Rules-info-6-upload-info": "Uploading the report will give you a 25% discount on the next reverse report.",
      "Rules-info-6-upload-button": "You can upload the file here.",
      "Rules-label-7": "Agreements? We already have a ready-made template",
      "Rules-info-7-upload-info": "Disclosure document and post-check agreement/letter of intent.",
      "Rules-info-7-upload-button": "Download from the website and sign.",
      "Rules-label-8": "Starting a new journey, good luck!",
      "Car number": "Car Number",
      "Choose Language": "Choose Language"
    }
  },
  ru: {
    translation: {
      "Home-Title": "Проверка автомобиля перед покупкой",
      "Home-Subtitle": "Аварии, залоги, смена владельцев",
      "Home-Sale": "Сейчас по стартовой цене всего - 79.90 шекелей",
      "Home-Car number placeholder": "Введите номер автомобиля",
      "Home-Check button": "Проверить",
      "Checking the seller vehicle": "Проверка права собственности на автомобиль",
      "Official mortgage check": "Официальная проверка залогов на автомобиль",
      "Vehicle history from database": "История автомобиля из базы данных",
      "Impairment test and total loss": "Проверка списания и обесценивания",
      "Maintenance costs and red flags": "Советы по обслуживанию подержанного автомобиля",
      "Start with": "Начните с этого",
      "Start with info": "Reverse возвращает вас в историю автомобиля, собирает данные для вас и предоставляет \"резюме\" автомобиля как раз вовремя, перед покупкой подержанного автомобиля. Был ли он списан? Возможно, он украден или находится в залоге? Какова его история владения? Возможно, есть что-то в его прошлом, что пытаются скрыть. Все, что нужно знать – за 5 минут, прямо на ваш email.",
      "Check before seen": "Проверьте автомобиль до того, как увидите его",
      "Check before seen info": "Наши системы собирают данные из всех официальных баз. Вам остается только нажать и получить результаты в течение нескольких минут, именно тогда, когда это актуально для покупки.",
      "Vehicle CV": "Резюме автомобиля – что это значит?",
      "Vehicle CV info": "Все просто: понятный, читаемый документ, отправляемый прямо на ваш email, с подробностями о данных, известных органам власти о подержанном автомобиле, который вы рассматриваете для покупки: аварии, залоги, обесценивание, история владения, страховые претензии – все.",
      "Check list": "Что именно проверяется",
      "Check list info": "Reverse к предыдущим владельцам | Reverse к залогам | Reverse к истории автомобиля | Reverse к обесцениванию и списанию | Reverse к обслуживанию, предупреждениям и отзывам",
      "Guide": "Обучающее видео",
      "How report looks": "Как выглядит отчет – узнайте",
      "Full name": "Полное имя",
      "Full name require": "Пожалуйста, введите ваше полное имя!",
      "Phone number": "Номер телефона",
      "Phone number require": "Пожалуйста, введите ваш номер телефона!",
      "Email address": "Адрес электронной почты",
      "Email address require": "Пожалуйста, введите ваш email!",
      "Send": "Отправить",
      "The commandments": "Заповеди",
      "Report of stolen vehicle": "Сообщение об угнанном автомобиле",
      "Report of a car seller": "Сообщение о продавце автомобиля",
      "Accessibility": "Доступность",
      "To cancel an order": "Отмена заказа",
      "Terms": "Условия использования",
      "Privacy Policy": "Политика конфиденциальности",
      "Contact us": "Свяжитесь с Reverse!",
      "Drawer title": "Настройки",
      "User logged out": "Пользователь вышел из системы",
      "Log In": "Войти",
      "Logged as": "Выполнен вход как",
      "Logout": "Выйти",
      "Login account": "Вход в аккаунт",
      "LOGIN": "Войти",
      "OR REGISTER": "Или зарегистрироваться",
      "Something went wrong": "Что-то пошло не так",
      "User logged in": "Пользователь вошел в систему",
      "Email address is not valid": "Некорректный адрес электронной почты",
      "Password require": "Пожалуйста, введите ваш пароль!",
      "Password": "Пароль",
      "Reset password": "Сбросить пароль",
      "Remember me": "Запомнить меня",
      "Password conditions": "Минимум 8 символов, минимум 1 буква и 1 цифра",
      "Confirm password": "Подтвердите пароль",
      "Confirm password require": "Пожалуйста, подтвердите ваш пароль!",
      "Passwords do not match": "Пароли не совпадают",
      "SIGNUP": "Зарегистрироваться",
      "BACK": "Назад",
      "Reset password - Alert description": "Запрос на сброс пароля был отправлен на ваш email. Пожалуйста, проверьте свою почту, чтобы продолжить процесс.",
      "Show report": "Показать отчет",
      "Show reports history": "Показать историю отчетов",
      "Created date": "Дата создания",
      "File": "Файл",
      "Main page": "Главная страница",
      "Email sent successfully": "Email успешно отправлен",
      "Email sent description": "Мы получили ваш запрос и свяжемся с вами в ближайшее время.",
      "Owner ID": "Идентификационный номер основного владельца автомобиля",
      "Owner date": "Дата начала владения автомобилем (согласно регистрации)",
      "Report preparation alert": "Спасибо, ваш отчет готовится и может занять несколько минут. Как только он будет готов, вы сможете просмотреть его на странице ваших отчетов.",
      "Search another car": "Найти другой автомобиль",
      "Report demo": "Как выглядит отчет",
      "Order report": "Заказать отчет",
      "Why-menu": "Почему?",
      "Why-title": "Почему стоит выбрать Reverse?",
      "Why-subtitle": "Информация от Reverse может уберечь вас от покупки дефектного автомобиля – как механически, так и юридически. Хотите спокойствия? Выбирайте Reverse.",
      "Why-label-1": "Проверка арестованного автомобиля",
      "Why-info-1": "Кредитор, стремящийся вернуть свои деньги, открывает дело в службе судебных приставов, из-за чего в Министерстве транспорта регистрируется арест на определенный номер автомобиля. Тот, кто попытается осуществить электронную передачу права собственности в одном из отделений почтовой службы и обнаружит, что автомобиль арестован, не сможет завершить передачу права собственности.",
      "Why-label-2": "Как решить проблему?",
      "Why-info-2": "Если покупатель заинтересован в этом автомобиле, существует два возможных решения, при условии, что текущий владелец признает долг и понимает необходимость его уплаты.",
      "Why-info-2-checkmark-1": "Покупатель оплачивает долг, тем самым снимая арест с автомобиля. После этого он выплачивает продавцу оставшуюся сумму, согласованную за автомобиль, за вычетом оплаченного долга.",
      "Why-info-2-checkmark-2": "Если продавец сам оплачивает долг, он должен доказать, что арест снят, прежде чем можно будет выполнить электронную передачу права собственности.",
      "Why-label-3": "Заложенный автомобиль",
      "Why-info-3": "Владелец автомобиля, получивший финансирование для его покупки, закладывает автомобиль в качестве гарантии возврата кредита. Перед покупкой убедитесь, что на автомобиле нет залога. Reverse выполняет эту проверку за вас.",
      "Why-label-4": "Автомобиль, зарегистрированный в реестре залогов",
      "Why-info-4": "Другой способ предотвратить передачу права собственности — зарегистрировать данные конкретного автомобиля в реестре залогов, тем самым предотвращая его продажу другому лицу.",
      "Why-label-5": "Простой способ избежать проблем",
      "Why-info-5": "Небольшая проверка может избавить вас от большого количества проблем. Используйте Reverse, чтобы узнать все, что скрывают о машине, которую вы собираетесь купить.",
      "Handicapped-menu": "Парковочное разрешение для инвалидов",
      "Handicapped-title": "Парковочное разрешение для инвалидов: что нужно знать перед покупкой",
      "Handicapped-subtitle": "Вы точно не хотите оказаться в ситуации, когда вы не можете передать право собственности? Чтобы избежать ненужных проблем, убедитесь, что парковочное разрешение для инвалидов (если оно существует) аннулировано перед продажей автомобиля. Вот что нужно знать, чтобы сделать это правильно.",
      "Handicapped-label-1": "Что такое парковочное разрешение для инвалидов?",
      "Handicapped-info-1": "Парковочное разрешение для инвалидов — это лицензия, позволяющая парковаться на местах, зарезервированных исключительно для инвалидов. Официально признанное в Израиле, разрешение выдается владельцам автомобилей, соответствующим критериям Министерства лицензирования. Это облегчает повседневную жизнь владельцев разрешений, оно размещается на лобовом стекле автомобиля и зарегистрировано в базе Министерства лицензирования.",
      "Handicapped-label-2": "Покупаете машину? Не забудьте проверить наличие разрешения для инвалидов",
      "Handicapped-info-2": "Один из важных моментов при покупке подержанного автомобиля — проверить, связано ли с машиной разрешение для инвалидов, которое позволяет парковаться на специальных местах. Покупатели, не зная об этом разрешении, могут столкнуться с проблемами в будущем.",
      "Handicapped-label-3": "Что делать в лицензирующем органе?",
      "Handicapped-info-3": "Из-за привилегий и льгот для инвалидов Министерство транспорта не позволяет передавать это разрешение новому владельцу автомобиля, если он не соответствует критериям. Перед передачей права собственности текущий владелец должен вернуть парковочное разрешение для инвалидов в лицензирующий орган, чтобы статус регистрационного номера соответствовал обычным автомобилям. Только после этого можно осуществить передачу права собственности.",
      "Handicapped-label-4": "Покупаете машину? Проверьте наличие разрешения для инвалидов заранее",
      "Handicapped-info-4": "Покупатели, не знающие о наличии разрешения для инвалидов, могут столкнуться с отказом в передаче права собственности из-за этого разрешения. В таких случаях покупатель должен попросить предыдущего владельца аннулировать разрешение, прежде чем продолжать процесс.",
      "Curved vehicle-menu": "Арестованный или заложенный автомобиль",
      "Curved vehicle-title": "Арестованный или заложенный автомобиль: как избежать покупки?",
      "Curved vehicle-subtitle": "Что делать, чтобы не оказаться в ситуации, когда владелец машины на самом деле не её владелец? Верно: используйте Reverse.",
      "Curved vehicle-label-1": "Юридическая сторона вашего будущего автомобиля",
      "Curved vehicle-info-1": "Любой, кто хочет купить подержанный автомобиль, должен проверить его механическое, электрическое состояние, а также юридический статус. В некоторых случаях автомобиль может быть арестован или заложен, поэтому важно быть внимательным, чтобы избежать проблем.",
      "Curved vehicle-label-2": "Reverse решает проблему",
      "Curved vehicle-info-2": "Информация из истории автомобиля, предоставляемая Reverse, позволяет узнать, находится ли автомобиль под арестом, залогом или имеет другие важные нюансы.",
      "Curved vehicle-label-3": "Передача права собственности на автомобиль",
      "Curved vehicle-info-3": "Нашли автомобиль, который соответствует вашим требованиям? Отлично. Теперь убедитесь, что его юридический статус позволяет совершить сделку.",
      "Curved vehicle-label-4": "Залог автомобиля",
      "Curved vehicle-info-4": "Человек, имеющий долги перед различными организациями, такими как поставщики или налоговые органы, может столкнуться с арестом своих активов, включая автомобили. Цель заключается в реализации этих активов для погашения долгов.",
      "Curved vehicle-label-5": "Ипотека автомобиля",
      "Curved vehicle-info-5": "Некоторые организации оформляют ипотеку на автомобиль. С помощью Reverse вы можете проверить наличие ипотеки и узнать, кто её владелец.",
      "Recall-menu": "Приказ отзыва",
      "Recall-title": "Приказ отзыва? Есть дефектная деталь!",
      "Recall-subtitle": "Отзыв — это ситуация, когда производитель автомобиля объявляет, что в каком-то компоненте имеется дефект, который необходимо заменить во всех машинах. При покупке подержанного автомобиля важно убедиться, что автомобиль, который вы покупаете, прошел отзыв, если это было необходимо.",
      "Recall-label-1": "Когда производитель находит дефект в производстве, а машины уже на дороге",
      "Recall-info-1": "Производители автомобилей по всему миру придают большое значение вопросам безопасности, поэтому они тщательно контролируют все процессы ремонта и осмотра. Это продолжается с момента продажи автомобиля, так как автомобили проходят регулярное обслуживание и проверки в авторизованных сервисах согласно указаниям производителя.",
      "Recall-label-2": "Объявление об отзыве автомобиля",
      "Recall-info-2": "Если возникает одноразовый дефект или повторяющаяся проблема, инженеры автомобилей тщательно исследуют ее и часто приходят к выводу, что определенная деталь неисправна и требует замены для обеспечения безопасности водителей и пассажиров. Производитель затем изготавливает новую деталь, которая будет более подходящей, чем оригинальная. Следующий шаг — уведомить всех владельцев конкретной модели, исходя из года выпуска, и попросить их обратиться в авторизованный сервис для замены. Все владельцы таких автомобилей получают уведомление через каналы связи и могут записаться в авторизованный сервис для проведения замены.",
      "Recall-label-3": "Обязательство выполнить отзыв",
      "Recall-info-3": "Из-за важности выполнения отзыва для предотвращения небезопасных ситуаций, каждый владелец автомобиля обязан выполнить это как можно скорее.",
      "Recall-label-4": "Отзыв — обязательное условие для прохождения техосмотра",
      "Recall-info-4": "Каждый год каждый автомобиль проходит ежегодный техосмотр для проверки его состояния. Если владелец автомобиля приходит в центр техосмотра и выясняется, что он не выполнил требования производителя по отзыву, заменив конкретную деталь, он не пройдет ежегодный техосмотр. Только после выполнения необходимых действий и подтверждения, что все в порядке, он сможет пройти техосмотр и продолжить использовать автомобиль.",
      "Certified garage-menu": "Авторизованный сервис",
      "Certified garage-title": "Авторизованный сервис: Почему это необходимо?",
      "Certified garage-subtitle": "Министерство транспорта выдает лицензии и осуществляет контроль для того, чтобы водители были уверены, что их автомобиль в надежных руках.",
      "Certified garage-label-1": "Что такое авторизованный сервис?",
      "Certified garage-info-1": "Авторизованные сервисы, лицензированные Министерством транспорта, — это сервисы, которые работают под контролем министерства и обычно являются членами ассоциации механиков.",
      "Certified garage-label-2": "Каким стандартам должны соответствовать авторизованные сервисы?",
      "Certified garage-info-2": "В авторизованном сервисе Министерство транспорта предоставляет профессиональный уход за автомобилями всех типов и производителей. Эти сервисы обязаны работать в соответствии с жесткими правилами. Работники сервисов проходят обучение и сертификацию по правильным методам осмотра, ремонта и обслуживания автомобилей разных производителей. Сервис должен возглавлять механик с необходимой квалификацией, который управляет профессиональными процессами.",
      "Certified garage-label-3": "Прозрачность: как работает авторизованный сервис",
      "Certified garage-info-3": "В авторизованном сервисе должно быть размещено ценовое меню в видимом месте, в котором указаны цены на работы и оригинальные детали. Когда автомобиль поступает в сервис, работники обязаны сообщить клиенту о результатах осмотра, что нужно отремонтировать и какова стоимость работ, деталей и услуг. Работы начнутся только после того, как клиент подтвердит их выполнение.",
      "Certified garage-label-4": "Документация действий в авторизованном сервисе",
      "Certified garage-info-4": "В авторизованном сервисе вся информация о ремонтах, заменах деталей и выполнении обслуживания записывается в компьютерную систему. Это позволяет владельцам автомобилей, особенно при покупке подержанного автомобиля, получать полную информацию о ремонтах, например, когда заменяли тормоза или ремень ГРМ.",
      "Certified garage-label-5": "Уличные сервисы — как их распознать и избежать?",
      "Certified garage-info-5": "Уличные сервисы — это нелицензированные мастерские, работающие без контроля. Часто водители недовольны качеством обслуживания, так как им некому обратиться в случае проблем, и эти сервисы не контролируются Министерством транспорта. Цены в этих мастерских завышены, а установленные детали не всегда оригинальны или новые. Используются бывшие в употреблении детали, которые быстро выходят из строя или неисправны. Рекомендуется не ремонтировать автомобили в уличных сервисах.",
      "Car dealer-menu": "Лицензированный автомобильный дилер",
      "Car dealer-title": "Лицензированный автомобильный дилер: почему это важно?",
      "Car dealer-subtitle": "Хотите купить автомобиль, не рискуя попасть на сомнительную сделку? Покупайте у лицензированного автомобильного дилера, который имеет разрешение от Министерства транспорта, и ездите с уверенностью.",
      "Car dealer-label-1": "Что такое лицензированный автомобильный дилер?",
      "Car dealer-info-1": "Лицензированный автомобильный дилер — это профессионал, который получил разрешение от Министерства транспорта для ведения бизнеса в области торговли автомобилями, что гарантирует регулирование его деятельности.",
      "Car dealer-label-2": "Преимущества покупки у автомобильного дилера",
      "Car dealer-info-2-checkmark-1": "Покупка с гарантией",
      "Car dealer-info-2-checkmark-2": "Покупка у компании, заботящейся о своей репутации",
      "Car dealer-info-2-checkmark-3": "Покупка у профессионала, который знает и понимает особенности автомобиля и несет за них ответственность",
      "Car dealer-info-2-checkmark-4": "Получение автомобиля после улучшений",
      "Car dealer-label-3": "Они ремонтируют машину и продают ее",
      "Car dealer-info-3": "Цель автомобильных дилеров — приобрести автомобили по цене ниже рыночной и затем отправить их на ремонт к профессионалам, с которыми они сотрудничают, например, электрикам, механикам, кузовщикам, малярам и другим, в зависимости от состояния автомобиля. После необходимых ремонтов, таких как покраска и кузовные работы, автомобиль тщательно чистится как внутри, так и снаружи, и готовится к продаже. Затем дилер размещает объявление о продаже автомобиля в различных источниках.",
      "Car dealer-label-4": "Покупка подержанных автомобилей",
      "Car dealer-info-4": "Автомобильные дилеры покупают автомобили на аукционах, в трейд-ин и у частных лиц. Они имеют торговую лицензию, которая позволяет им работать с автомобилем до его продажи без передачи права собственности, что не добавляет еще одного владельца в документы. Эта лицензия также позволяет заморозить дату техосмотра на время продажи автомобиля, а новый владелец оплачивает техосмотр и передачу автомобиля для ежегодной проверки.",
      "How it works-menu": "Как это работает?",
      "How it works-title": "Как это работает?",
      "How it works-subtitle": "Три шага, и у вас будет история автомобиля с данными и аналитикой для поездки.",
      "How it works-label-1": "Первый шаг: 7 лет назад",
      "How it works-info-1": "Мы предоставляем вам информацию о наиболее чувствительных моментах при покупке подержанного автомобиля.",
      "How it works-info-1-checkmark-1": "Был ли в авариях?",
      "How it works-info-1-checkmark-2": "Потеря стоимости из-за аварий?",
      "How it works-info-1-checkmark-3": "Был ли автомобиль угнан?",
      "How it works-info-1-checkmark-4": "Был ли автомобиль застрахован?",
      "How it works-info-1-checkmark-5": "Был ли автомобиль признан списанным?",
      "How it works-info-1-checkmark-6": "Были ли выплачены страховые возмещения?",
      "How it works-label-2": "Шаг второй: Предыдущие владельцы",
      "How it works-info-2": "Тот, кто продает третье по счету авто, возможно, не знает всего о его прошлом.",
      "How it works-info-2-checkmark-1": "Был ли автомобиль когда-либо в собственности коммерческого лица?",
      "How it works-info-2-checkmark-2": "Есть ли причины подозревать что-то в предыдущих владельцах?",
      "How it works-label-3": "Шаг третий: Заключения и инструменты для правильной сделки",
      "How it works-info-3": "Мы обеспечиваем вас информацией о самых важных моментах при покупке подержанного автомобиля.",
      "How it works-info-3-checkmark-1": "Есть ли проблемные данные, на которые стоит обратить внимание?",
      "How it works-info-3-checkmark-2": "Оставляет ли этот автомобиль хорошее впечатление?",
      "How it works-info-3-checkmark-3": "Что наш ИИ говорит об этой возможности?",
      "How it works-info-4": "Наша система ИИ помогает вам с анализом, резюме и выводами, фактически размышляя вместе с вами: какой шаг лучше всего предпринять сейчас?",
      "How it works-info-4-checkmark-1": "Сколько стоит заплатить?",
      "How it works-info-4-checkmark-2": "Амортизация — что говорит наш прогноз?",
      "How it works-info-4-checkmark-3": "Сколько будет стоить обслуживание этого автомобиля?",
      "How it works-info-4-checkmark-4": "Формы для продажи",
      "How it works-info-4-checkmark-5": "Руководства и советы: как действовать при продаже или покупке",
      "Total loss-menu": "Тотальный ущерб",
      "Total loss-title": "Тотальный ущерб? Как не покупать такой автомобиль",
      "Total loss-subtitle": "Тотальный ущерб — это ситуация, когда автомобиль снимается с учета после того, как ущерб, полученный в аварии, равен или превышает 60% его стоимости. Каков процесс объявления такого автомобиля? Вся информация здесь.",
      "Total loss-label-1": "Оценщик автомобилей — специалист для таких ситуаций",
      "Total loss-info-1": "Тег инвалидности — это лицензия на парковку на местах, предназначенных только для инвалидов, выдается официальными властями Израиля. Владелец автомобиля, который соответствует критериям и получает сертификат от лицензирующего органа, получает тег инвалидности, который облегчает повседневную жизнь. Этот тег приклеивается к лобовому стеклу автомобиля и фиксируется в регистрах Министерства транспорта.",
      "Total loss-label-2": "Тотальный ущерб — что это?",
      "Total loss-info-2": "Тотальный ущерб — это ситуация, когда ремонт автомобиля экономически нецелесообразен, поэтому оценщик снимает его с учета. Это решение имеет несколько последствий.",
      "Total loss-info-2-checkmark-1": "Поскольку автомобиль обычно не может двигаться по дорогам, эвакуатор забирает его на свалку для полной разборки. Некоторые детали перерабатываются, а исправные части продаются как бывшие в употреблении, но рабочие.",
      "Total loss-info-2-checkmark-2": "Водитель должен сообщить в регистрационный орган, что автомобиль был разобран и поэтому должен быть снят с регистрационного учета.",
      "Total loss-info-2-checkmark-3": "Страховая компания выплачивает владельцу автомобиля его полную стоимость, согласно оценке оценщика.",
      "Total loss-label-3": "Частичный ущерб стоимости автомобиля",
      "Total loss-info-3": "После относительно легкой аварии оценщик разрешает профессионалам из гаража провести определенные ремонты, которые оплачивает страховая компания виновника. Однако эти ремонты приводят к снижению стоимости автомобиля на определенный процент. Для каждой части автомобиля есть свой процент снижения стоимости, например:",
      "Total loss-info-3-example-1": "Заднее крыло — X%",
      "Total loss-info-3-example-2": "Рама кузова — Y%",
      "Total loss-info-3-example-3": "Повреждения кузова — Z%",
      "Total loss-label-4": "Страхование всегда в процессе",
      "Total loss-info-4": "Страховая компания выплачивает владельцу автомобиля компенсацию за потерю стоимости, так как это произошло из-за аварии, не по его вине, и при продаже автомобиля он получит меньшую сумму из-за этого.",
      "Rules-menu": "Правила",
      "Rules-title": "Правила при покупке подержанного автомобиля",
      "Rules-subtitle": "Что необходимо сделать перед покупкой подержанного автомобиля",
      "Rules-label-1": "Поговорите с владельцем/водителем",
      "Rules-info-1": "На этом этапе очень важно запросить актуальные фотографии автомобиля и узнать о пробеге, предыдущих владельцах, истории неисправностей и ремонтов.",
      "Rules-label-2": "Проверьте автомобиль задним ходом",
      "Rules-info-2": "При помощи заднего хода можно познакомиться с автомобилем, который вы рассматриваете для покупки, еще до того, как вы поедете его осматривать и встретитесь с владельцем.",
      "Rules-label-3": "Проверьте автомобиль задним ходом",
      "Rules-info-3": "При помощи заднего хода можно познакомиться с автомобилем, который вы рассматриваете для покупки, еще до того, как вы поедете его осматривать и встретитесь с владельцем.",
      "Rules-label-4": "Тест-драйв и внешний осмотр",
      "Rules-info-4": "Осмотрите автомобиль снаружи, откройте капот, чтобы проверить на наличие ослабленных соединений, утечек, состояние аккумулятора, и возьмите автомобиль на тест-драйв. Очень важно выключить радио, открыть окна и послушать необычные звуки. Проверьте, работает ли кондиционер.",
      "Rules-label-5": "Проверьте данные",
      "Rules-info-5": "Убедитесь, что данные совпадают, и что владелец действительно является зарегистрированным владельцем, согласно регистрации автомобиля и удостоверению личности продавца.",
      "Rules-info-5-important-1": "Прочитайте историю автомобиля — были ли у автомобиля аварии с потерей стоимости и какой был последний зафиксированный пробег? Сейчас самое время решить, честная ли это сделка или нет.",
      "Rules-info-5-important-2": "Очень важно!",
      "Rules-label-6": "Не купили? Ваш отчет стоит денег",
      "Rules-info-6": "Если автомобиль не подошел вам, загрузите отчет о покупке автомобиля (поскольку вы его все равно не будете использовать).",
      "Rules-info-6-upload-info": "Загрузка отчета даст вам скидку 25% на следующий отчет о заднем ходе.",
      "Rules-info-6-upload-button": "Вы можете загрузить файл здесь.",
      "Rules-label-7": "Соглашения? У нас уже есть готовый шаблон",
      "Rules-info-7-upload-info": "Документ раскрытия информации и пост-проверочное соглашение/письмо о намерениях.",
      "Rules-info-7-upload-button": "Скачайте с сайта и подпишите.",
      "Rules-label-8": "Начинаем новый путь, удачи!",
      "Car number": "Номер автомобиля",
      "Choose Language": "Выберите язык"
    }
  },
  ar: {
    translation: {
      "Home-Title": "فحص السيارة قبل الشراء",
      "Home-Subtitle": "الحوادث، الرهن، تغيير الملكية",
      "Home-Sale": "الآن بسعر إطلاق خاص - 79.90 شيكل",
      "Home-Car number placeholder": "أدخل رقم اللوحة",
      "Home-Check button": "تحقق",
      "Checking the seller vehicle": "فحص ملكية السيارة",
      "Official mortgage check": "فحص الرهن الرسمي للسيارة",
      "Vehicle history from database": "تاريخ السيارة من قاعدة البيانات",
      "Impairment test and total loss": "اختبار التلف الكامل وانخفاض القيمة",
      "Maintenance costs and red flags": "قواعد لفحص السيارات المستعملة",
      "Start with": "ابدأ بهذا",
      "Start with info": "ريڤيرس يأخذك إلى الماضي لمعرفة تاريخ السيارة، يجمع المعلومات ويقدم لك السيرة الذاتية للسيارة في الوقت المناسب. قبل شراء سيارة مستعملة، هل تعرضت لتلف كامل؟ ربما السيارة مسروقة أو مرهونة؟ ما هو تاريخ ملكيتها؟ قد يكون هناك شيء مخفي في ماضيها. كل ما تحتاج إلى معرفته – خلال 5 دقائق، مباشرة إلى بريدك الإلكتروني.",
      "Check before seen": "تحقق من السيارة قبل أن تراها",
      "Check before seen info": "أنظمتنا تجمع البيانات من جميع قواعد البيانات الرسمية. كل ما عليك هو النقر والحصول على النتيجة خلال دقائق، في الوقت المناسب للشراء.",
      "Vehicle CV": "سيرة السيارة الذاتية - ما المقصود بها",
      "Vehicle CV info": "بكل بساطة: مستند واضح ومقروء يتم إرساله مباشرة إلى بريدك الإلكتروني، يحتوي على تفاصيل البيانات التي تعرفها السلطات عن السيارة المستعملة التي تفكر في شرائها: الحوادث، الرهن، انخفاض القيمة، تاريخ الملكية، مطالبات التأمين – كل شيء.",
      "Check list": "ما الذي يتم فحصه هنا",
      "Check list info": "ريڤيرس لتاريخ المالكين السابقين | ريڤيرس للرهون | ريڤيرس لتاريخ السيارة | ريڤيرس لانخفاض القيمة والتلف الكامل | ريڤيرس للصيانة والتحذيرات والاستدعاءات",
      "Guide": "فيديو تعليمي",
      "How report looks": "تعرف على التقرير - كيف يبدو",
      "Full name": "الاسم الكامل",
      "Full name require": "يرجى إدخال اسمك الكامل!",
      "Phone number": "رقم الهاتف",
      "Phone number require": "يرجى إدخال رقم هاتفك!",
      "Email address": "عنوان البريد الإلكتروني",
      "Email address require": "يرجى إدخال بريدك الإلكتروني!",
      "Send": "إرسال",
      "The commandments": "القواعد",
      "Report of stolen vehicle": "تقرير عن سيارة مسروقة",
      "Report of a car seller": "تقرير عن بائع سيارة",
      "Accessibility": "إمكانية الوصول",
      "To cancel an order": "لإلغاء الطلب",
      "Terms": "الشروط والأحكام",
      "Privacy Policy": "سياسة الخصوصية",
      "Contact us": "تواصل معنا في ريڤيرس!",
      "Drawer title": "الإعدادات",
      "User logged out": "تم تسجيل الخروج",
      "Log In": "تسجيل الدخول",
      "Logged as": "تم تسجيل الدخول كـ",
      "Logout": "تسجيل الخروج",
      "Login account": "تسجيل الدخول إلى الحساب",
      "LOGIN": "تسجيل الدخول",
      "OR REGISTER": "أو سجل",
      "Something went wrong": "حدث خطأ ما",
      "User logged in": "تم تسجيل الدخول",
      "Email address is not valid": "البريد الإلكتروني غير صالح",
      "Password require": "يرجى إدخال كلمة المرور!",
      "Password": "كلمة المرور",
      "Reset password": "إعادة تعيين كلمة المرور",
      "Remember me": "تذكرني",
      "Password conditions": "8 أحرف على الأقل، على الأقل حرف واحد ورقم واحد",
      "Confirm password": "تأكيد كلمة المرور",
      "Confirm password require": "يرجى إدخال كلمة المرور للتأكيد!",
      "Passwords do not match": "كلمتا المرور غير متطابقتين",
      "SIGNUP": "تسجيل",
      "BACK": "العودة",
      "Reset password - Alert description": "تم إرسال طلب إعادة تعيين كلمة المرور إلى بريدك الإلكتروني. يرجى التحقق من صندوق الوارد الخاص بك لمتابعة العملية.",
      "Show report": "عرض التقرير",
      "Show reports history": "عرض سجل التقارير",
      "Created date": "تاريخ الإنشاء",
      "File": "ملف",
      "Main page": "الصفحة الرئيسية",
      "Email sent successfully": "تم إرسال البريد الإلكتروني بنجاح",
      "Email sent description": "لقد تلقينا طلبك وسنتواصل معك قريبًا.",
      "Owner ID": "رقم هوية المالك الرئيسي للسيارة",
      "Owner date": "تاريخ بداية الملكية للسيارة (حسب رخصة السيارة)",
      "Report preparation alert": "شكرًا، تقريرك قيد الإعداد وقد يستغرق بضع دقائق. بمجرد الانتهاء، يمكنك مشاهدته في صفحة تقاريرك.",
      "Search another car": "ابحث عن سيارة أخرى",
      "Report demo": "كيف يبدو التقرير",
      "Order report": "اطلب تقرير",
      "Why-menu": "لماذا؟",
      "Why-title": "لماذا يجب اختيار ريڤيرس؟",
      "Why-subtitle": "المعلومات التي تقدمها ريڤيرس يمكن أن توفر عليك شراء سيارة معيبة، سواء ميكانيكيًا أو قانونيًا. تريد راحة البال؟ اختر ريڤيرس.",
      "Why-label-1": "فحص سيارة محجوزة",
      "Why-info-1": "الشخص المدين الذي يحاول استرداد أمواله يفتح ملفًا في التنفيذ، وبالتالي يتم تسجيل حجز على السيارة في وزارة النقل بناءً على رقم التسجيل الخاص بالسيارة. من يحاول إجراء نقل ملكية إلكتروني في أحد فروع البريد، ويكتشف أن السيارة محجوزة، لن يتمكن من إجراء نقل الملكية.",
      "Why-label-2": "كيف نحل المشكلة؟",
      "Why-info-2": "إذا كان المشتري مهتمًا بهذه السيارة، هناك خياران لحل المشكلة، ولكن فقط إذا اعترف المالك الحالي بالديون وفهم أنه يجب دفعها.",
      "Why-info-2-checkmark-1": "يدفع المشتري الدين وبالتالي يتم رفع الحجز عن السيارة. ثم يدفع للمشتري المبلغ المتبقي من سعر السيارة الذي تم الاتفاق عليه، مع خصم قيمة الدين المدفوع.",
      "Why-info-2-checkmark-2": "إذا دفع البائع الدين بنفسه وأثبت أن الحجز قد تم رفعه، يمكن إجراء نقل الملكية الإلكتروني.",
      "Why-label-3": "سيارة مرهونة",
      "Why-info-3": "إذا حصل صاحب السيارة على تمويل لشراء السيارة، فإنه يرهن السيارة كضمان لاسترداد أموال القرض. قبل الشراء، يجب التأكد من أن السيارة غير مرهونة. Reverse يقوم بهذا الفحص نيابة عنك.",
      "Why-label-4": "سيارة مسجلة في سجل الرهونات",
      "Why-info-4": "طريقة أخرى لتجنب نقل الملكية هي تسجيل بيانات السيارة في سجل الرهونات، وبالتالي منع بيعها لشخص آخر.",
      "Why-label-5": "الطريقة البسيطة لتجنب المشاكل",
      "Why-info-5": "يمكن لفحص صغير أن يوفر لك الكثير من المتاعب. استخدم Reverse لاكتشاف كل ما لم يخبرك به البائع عن السيارة التي تنوي شرائها.",
      "Handicapped-menu": "شهادة إعاقة",
      "Handicapped-title": "شهادة الإعاقة: يجب الانتباه قبل الشراء",
      "Handicapped-subtitle": "بالطبع لا تريد أن تجد نفسك في وضع لا يمكنك فيه نقل الملكية، أليس كذلك؟ لتجنب المشاكل غير الضرورية، يجب التأكد من إلغاء شهادة الإعاقة (إذا كانت موجودة) قبل بيع السيارة. إليك كل ما يجب معرفته للقيام بذلك بشكل صحيح.",
      "Handicapped-label-1": "ما هي شهادة الإعاقة؟",
      "Handicapped-info-1": "شهادة الإعاقة هي تصريح يسمح بالوقوف في الأماكن المخصصة لذوي الاحتياجات الخاصة فقط – يتم إصدارها من قبل الجهات الرسمية في دولة إسرائيل. يحصل مالك السيارة الذي يستوفي المعايير من وزارة الترخيص على شهادة الإعاقة، والتي تسهل عليه الحياة اليومية، ويعلق صاحب السيارة الشارة على الزجاج الأمامي للسيارة، ويتم تسجيلها في سجلات وزارة الترخيص.",
      "Handicapped-label-2": "هل تشتري سيارة؟ لا تنسى التحقق من موضوع شهادة الإعاقة",
      "Handicapped-info-2": "أحد الأمور التي يجب التحقق منها عند شراء سيارة مستعملة هو ما إذا كانت السيارة مرتبطة بشهادة إعاقة، والتي تسمح بالوقوف في أماكن مخصصة. الشخص الذي يشتري سيارة مستعملة وقد يواصل الاحتفاظ بها (حتى دون علمه) قد يواجه مشاكل في المستقبل.",
      "Handicapped-label-3": "ماذا يحدث في وزارة الترخيص؟",
      "Handicapped-info-3": "نظرًا للمزايا والإعفاءات المتعلقة بالوقوف لذوي الاحتياجات الخاصة، فإن وزارة النقل لا تسمح بنقل هذه الميزة إلى المالك الجديد للسيارة، حيث أنهم ليسوا معترف بهم كذوي إعاقة من قبل وزارة الترخيص. لذلك، يجب على المالك الحالي أن يعيد الشارة إلى وزارة الترخيص قبل نقل الملكية، لكي يصبح وضع رقم التسجيل كما هو بالنسبة لأي سيارة عادية بدون أي امتيازات. فقط بعد إتمام هذه الخطوة يمكن إجراء نقل الملكية.",
      "Handicapped-label-4": "هل تشتري سيارة؟ تحقق من موضوع شهادة الإعاقة مسبقًا",
      "Handicapped-info-4": "إذا اشترى شخص سيارة ولم يكن يعلم بوجود شهادة إعاقة، فإنه سيواجه رفضًا في نقل الملكية بسبب هذه الشهادة. في هذه الحالات، يجب على المشتري أن يطلب من صاحب السيارة اتخاذ الخطوات اللازمة قبل إجراء التغيير المطلوب.",
      "Curved vehicle-menu": "سيارة محجوزة أو مرهونة",
      "Curved vehicle-title": "سيارة محجوزة أو مرهونة: كيف تتجنب الشراء؟",
      "Curved vehicle-subtitle": "ماذا تفعل لتجنب الوقوع في وضع تجد فيه نفسك على وشك شراء سيارة ثم تكتشف أن المالكين ليسوا مالكين بالكامل؟ لقد خمنت بشكل صحيح: استخدم Reverse.",
      "Curved vehicle-label-1": "الجانب القانوني لسيارتك القادمة",
      "Curved vehicle-info-1": "أي شخص يريد شراء سيارة مستعملة يجب عليه فحص حالتها من الناحية الميكانيكية والكهربائية وكذلك من الناحية القانونية. هناك حالات قد تكون فيها السيارة محجوزة أو مرهونة، لذلك يجب أن تكون حذرًا لتجنب الوقوع في مشاكل.",
      "Curved vehicle-label-2": "Reverse يحل المشكلة",
      "Curved vehicle-info-2": "المعلومات التي تحصل عليها من تاريخ حياة السيارة عبر Reverse تساعدك في معرفة ما إذا كانت السيارة محجوزة أو مرهونة وأيضًا العديد من التفاصيل الأخرى التي يجب التحقق منها.",
      "Curved vehicle-label-3": "نقل ملكية السيارة",
      "Curved vehicle-info-3": "وجدت سيارة تناسب احتياجاتك؟ ممتاز. الآن، من الأفضل التحقق من أن وضعها القانوني يسمح بإتمام الصفقة.",
      "Curved vehicle-label-4": "رهون السيارة",
      "Curved vehicle-info-4": "الشخص الذي عليه ديون لأطراف مختلفة مثل الموردين أو السلطات الضريبية، أو أي طرف آخر قد يتم فتح ملف تنفيذي ضده. بهذه الطريقة، يتم حجز الأموال والممتلكات والمركبات التي يمتلكها هذا الشخص بهدف تنفيذ هذه الممتلكات جزئيًا أو كليًا من أجل سداد الديون المستحقة.",
      "Curved vehicle-label-5": "رهن السيارة",
      "Curved vehicle-info-5": "هناك أطراف تقوم برهن السيارات. من خلال Reverse، يمكنك معرفة إذا كان هناك رهن على السيارة ومع من.",
      "Recall-menu": "أمر سحب",
      "Recall-title": "أمر سحب؟ هناك جزء معيب!",
      "Recall-subtitle": "السحب هو حالة يعلن فيها مصنع السيارات عن وجود عيب في جزء معين من المركبة يجب استبداله في جميع السيارات. عند شراء سيارة مستعملة، يجب التأكد من أن السيارة التي تشتريها قد مرّت بعملية السحب إذا كانت هناك حاجة لذلك.",
      "Recall-label-1": "عندما يكتشف المصنع عيبًا في الإنتاج والسيارات بالفعل على الطريق",
      "Recall-info-1": "يولي مصنّعو السيارات في جميع أنحاء العالم اهتمامًا كبيرًا لسلامة المركبات، لذا فإنهم يتابعون جميع عمليات الإصلاح والفحص. ويستمر ذلك منذ لحظة بيع السيارة، حيث تخضع السيارات للفحوصات والصيانات الدورية في الورش المعتمدة وفقًا لتوجيهات المصنع.",
      "Recall-label-2": "إعلان السحب للسيارات",
      "Recall-info-2": "إذا كان هناك خلل مفرد أو مشكلة متكررة، يقوم مهندسو السيارات بفحص الأمر بشكل دقيق وغالبًا ما يتوصلون إلى أن هناك مشكلة تقنية في بعض الأجزاء، وبالتالي يجب استبدالها لحماية السائقين والركاب وضمان سلامتهم. بعد ذلك، يقوم المصنع بتصنيع القطع البديلة التي تكون أكثر ملائمة من القطعة الأصلية. الخطوة التالية هي دعوة جميع مالكي السيارة من الطراز المحدد وفقًا لسنة الصنع للذهاب إلى ورشة معتمدة لإجراء الاستبدال. يتلقى كل من يشتري سيارة من هذا النوع إشعارًا عبر وسائل الاتصال المختلفة ويقوم بتحديد موعد في ورشة معتمدة لإجراء التغيير الفعلي.",
      "Recall-label-3": "الالتزام بتنفيذ السحب الفعلي",
      "Recall-info-3": "نظرًا لأهمية تنفيذ السحب لتجنب الحالات غير الآمنة، يجب على كل مالك سيارة تنفيذ هذه العملية في أقرب وقت ممكن.",
      "Recall-label-4": "السحب، شرط أساسي لاجتياز الفحص الفني",
      "Recall-info-4": "كل عام، تخضع كل سيارة لاختبار فني سنوي للتحقق من حالتها. إذا وصل مالك السيارة إلى مركز الفحص الفني واكتشف أن السيارة لم تُنفّذ التعليمات اللازمة وفقًا لمتطلبات المصنع لاستبدال الجزء المعين، فلن يتمكن من اجتياز الفحص الفني السنوي. فقط بعد تنفيذ الإجراء المطلوب وإثبات أن كل شيء على ما يرام، يمكنه اجتياز الفحص الفني ومواصلة القيادة بالسيارة.",
      "Certified garage-menu": "ورشة معتمدة",
      "Certified garage-title": "ورشة معتمدة: لماذا هي ضرورية؟",
      "Certified garage-subtitle": "وزارة النقل تمنح الترخيص والإشراف لنا نحن السائقين لضمان أن سياراتنا في أيدٍ أمينة.",
      "Certified garage-label-1": "ما هي الورشة المعتمدة؟",
      "Certified garage-info-1": "الورش المعتمدة من وزارة النقل هي ورش تخضع للإشراف المباشر من الوزارة وغالبًا ما تكون أعضاء في اتحاد الورش.",
      "Certified garage-label-2": "ما هي المعايير التي يجب أن تلتزم بها الورش المعتمدة؟",
      "Certified garage-info-2": "في الورشة المعتمدة، توفر وزارة النقل صيانة محترفة للسيارات من جميع الأنواع ومن جميع الشركات المصنعة. يجب على هذه الورش الالتزام باللوائح الصارمة. كما يتلقى موظفو الورش تدريبًا مهنيًا متخصصًا حول كيفية فحص وإصلاح وصيانة السيارات من مختلف الشركات المصنعة. يجب أن يكون رئيس الورشة ميكانيكيًا معتمدًا وله التدريب المحدد الذي يدير العمليات الفنية.",
      "Certified garage-label-3": "الشفافية: كيف تعمل الورشة المعتمدة",
      "Certified garage-info-3": "يجب أن يكون في الورشة المعتمدة قائمة أسعار معلقة في مكان مرئي. توضح هذه القائمة أسعار الساعة وتكلفة القطع الأصلية. عند استلام السيارة للصيانة، يتعين على موظفي الورشة إبلاغ العميل بالنتائج التي توصلوا إليها، وما هي الإصلاحات المطلوبة وتكاليفها. يتم بدء العمل فقط بعد موافقة العميل على تنفيذ الصيانة.",
      "Certified garage-label-4": "توثيق الإجراءات في الورشة المعتمدة",
      "Certified garage-info-4": "في الورشة المعتمدة، يتم تسجيل جميع المعلومات المتعلقة بالإصلاحات التي تم إجراؤها على السيارة، مثل الأجزاء التي تم استبدالها، ومتى تم تنفيذ الصيانة الدورية، وأي معلومات أخرى في النظام الإلكتروني. يتيح ذلك للمالكين الاطلاع على المعلومات مثل متى تم تغيير الفرامل أو حزام التوقيت وما إلى ذلك. هذه المعلومات مهمة لجميع مالكي السيارات، وخاصة عند شراء سيارة مستعملة.",
      "Certified garage-label-5": "ورش الشوارع – كيف تكتشفها وتحذر منها؟",
      "Certified garage-info-5": "ورش الشوارع هي ورش غير مرخصة تعمل بدون إشراف. لذلك، في كثير من الأحيان، لا يكون السائقون راضين عن الصيانة التي تمت على سياراتهم لأنها لا تخضع للمراقبة من وزارة النقل. الأسعار في هذه الورش عادة ما تكون مرتفعة، وغالبًا ما يتم تركيب قطع غيار غير أصلية أو مستعملة، مما يؤدي إلى مشاكل في السيارة بسرعة. يُوصى بعدم إصلاح السيارات في ورش الشوارع.",
      "Car dealer-menu": "تاجر سيارات معتمد",
      "Car dealer-title": "تاجر سيارات معتمد: لماذا هو مهم؟",
      "Car dealer-subtitle": "هل ترغب في شراء سيارة دون المخاطرة بصفقة مشبوهة؟ اشترِ من تاجر سيارات معتمد يحمل ترخيصًا من وزارة النقل وكن مطمئنًا.",
      "Car dealer-label-1": "ما هو تاجر السيارات المعتمد؟",
      "Car dealer-info-1": "تاجر السيارات المعتمد هو شخص محترف حصل على موافقة من وزارة النقل لتشغيل عمله في مجال تجارة السيارات، وبالتالي يتم تنظيم نشاطه.",
      "Car dealer-label-2": "مزايا الشراء من تاجر سيارات معتمد",
      "Car dealer-info-2-checkmark-1": "شراء من مكان موثوق",
      "Car dealer-info-2-checkmark-2": "شراء من مكان يهتم بسمعته",
      "Car dealer-info-2-checkmark-3": "شراء من محترف يفهم خصائص السيارة ويكون مسؤولًا عنها",
      "Car dealer-info-2-checkmark-4": "الحصول على سيارة بعد صيانتها وتحسينها",
      "Car dealer-label-3": "هم يصلحون السيارة ويبيعونها",
      "Car dealer-info-3": "الهدف من كل تاجر سيارات هو شراء سيارات بأسعار أقل من قيمتها السوقية، ثم إرسالها للصيانة عند المحترفين الذين يتعاون معهم، مثل الكهربائيين والميكانيكيين وورش الصيانة والكهرباء. بعد الإصلاحات اللازمة مثل الطلاء وإصلاح الهيكل، يتم تنظيف السيارة بعناية من الداخل والخارج، ثم يتم عرضها للبيع في الأماكن المناسبة.",
      "Car dealer-label-4": "شراء السيارات المستعملة",
      "Car dealer-info-4": "تجار السيارات يشترون السيارات من المزادات، ومعارض السيارات، والأفراد. وهم يحملون شهادة تجارية تسمح لهم بإصلاح السيارة حتى بيعها دون نقل الملكية، مما يعني أنه لا يتم إضافة مالك جديد إلى السجلات. كما تسمح هذه الشهادة بتجميد تاريخ الفحص الفني عند بيع السيارة، ويتحمل المالك الجديد تكلفة الفحص الفني السنوي.",
      "How it works-menu": "كيف يعمل هذا؟",
      "How it works-title": "كيف يعمل هذا؟",
      "How it works-subtitle": "ثلاث خطوات، وستحصل على تاريخ السيارة مع البيانات والرؤى للانطلاق.",
      "How it works-label-1": "الخطوة الأولى: العودة 7 سنوات إلى الوراء",
      "How it works-info-1": "نقدم لك معلومات حول النقاط الأكثر حساسية عند شراء سيارة مستعملة.",
      "How it works-info-1-checkmark-1": "هل كانت السيارة في حوادث؟",
      "How it works-info-1-checkmark-2": "هل انخفضت قيمتها بسبب الحوادث؟",
      "How it works-info-1-checkmark-3": "هل تم سرقة السيارة من قبل؟",
      "How it works-info-1-checkmark-4": "هل كانت السيارة مؤمن عليها؟",
      "How it works-info-1-checkmark-5": "هل تم تصنيف السيارة على أنها خسارة كلية؟",
      "How it works-info-1-checkmark-6": "هل تم دفع تعويضات من شركات التأمين؟",
      "How it works-label-2": "الخطوة الثانية: الملاك السابقين",
      "How it works-info-2": "من يبيع سيارة يد ثالثة قد لا يعرف كل شيء عن ماضي السيارة.",
      "How it works-info-2-checkmark-1": "هل كانت السيارة مملوكة من قبل جهة تجارية؟",
      "How it works-info-2-checkmark-2": "هل يوجد سبب للشك في أحد الملاك السابقين؟",
      "How it works-label-3": "الخطوة الثالثة: الاستنتاجات والأدوات للصفقة الصحيحة",
      "How it works-info-3": "نزوّدكم بالمعلومات حول النقاط الحساسة عند شراء سيارة مستعملة.",
      "How it works-info-3-checkmark-1": "هل هناك بيانات مقلقة يجب الانتباه إليها؟",
      "How it works-info-3-checkmark-2": "هل تبدو هذه السيارة جيدة؟",
      "How it works-info-3-checkmark-3": "ماذا تقول الذكاء الاصطناعي لدينا عن هذه الفرصة؟",
      "How it works-info-4": "نظام الذكاء الاصطناعي يساعدكم بالتفكير والتحليل معكم: ما هو أفضل خطوة يجب اتخاذها الآن؟",
      "How it works-info-4-checkmark-1": "كم يجب أن تدفع؟",
      "How it works-info-4-checkmark-2": "الاستهلاك — ماذا يقول تنبؤنا؟",
      "How it works-info-4-checkmark-3": "كم ستكلف صيانة هذه السيارة؟",
      "How it works-info-4-checkmark-4": "نماذج البيع",
      "How it works-info-4-checkmark-5": "إرشادات ونصائح: كيفية التعامل مع البيع أو الشراء",
      "Total loss-menu": "الضرر الكلي",
      "Total loss-title": "ضرر كلي؟ كيف لا تشتري سيارة بهذا الشكل",
      "Total loss-subtitle": "الضرر الكلي هو الحالة التي يتم فيها سحب السيارة من الطريق بعد أن يصبح الضرر الناتج عن الحادث يعادل أو يزيد عن 60% من قيمتها. ما هو العملية وراء إعلان هذا النوع من السيارات؟ كل المعلومات هنا.",
      "Total loss-label-1": "مقيم السيارات — المختص لهذه الحالات",
      "Total loss-info-1": "شارة المعاق هي رخصة لركن السيارات في الأماكن المخصصة لذوي الإعاقة فقط، تصدر من قبل السلطات الرسمية في إسرائيل. الشخص الذي يمتلك سيارة والتي استوفت المعايير وحصلت على شهادة من وزارة النقل يحصل على الشارة، مما يسهل حياته اليومية.",
      "Total loss-label-2": "الضرر الكلي — ما هو؟",
      "Total loss-info-2": "الضرر الكلي هو الحالة التي تصبح فيها إصلاح السيارة غير مجدي اقتصاديًا، لذلك يقوم المقيم بسحب السيارة من الطريق. لهذا القرار عدة تداعيات.",
      "Total loss-info-2-checkmark-1": "بما أن السيارة لا تستطيع عادة السير على الطرق، يتم سحبها إلى مكب الخردة ليتم تفكيكها. بعض الأجزاء يتم إعادة تدويرها بينما يتم بيع الأجزاء السليمة كقطع غيار مستعملة.",
      "Total loss-info-2-checkmark-2": "يجب على السائق إبلاغ وزارة النقل بأن السيارة قد تم تفكيكها وبالتالي يجب إزالتها من سجل السيارات المسجلة في الدولة.",
      "Total loss-info-2-checkmark-3": "شركة التأمين تدفع للسائق قيمتها الكاملة وفقًا لتقدير المقيم.",
      "Total loss-label-3": "فقدان جزئي في قيمة السيارة",
      "Total loss-info-3": "بعد حادث بسيط نسبيًا، يوافق المقيم على إجراء إصلاحات محددة في ورشة الصيانة التي يتم دفع تكاليفها من قبل تأمين السائق المخطئ. ومع ذلك، فإن هذه الإصلاحات تؤدي إلى انخفاض القيمة بنسبة معينة. لكل جزء من أجزاء السيارة نسبة مئوية معينة مثل:",
      "Total loss-info-3-example-1": "الجناح الخلفي — X%",
      "Total loss-info-3-example-2": "إطار الهيكل — Y%",
      "Total loss-info-3-example-3": "التلف في الهيكل — Z%",
      "Total loss-label-4": "التأمين دائمًا في الصورة",
      "Total loss-info-4": "شركة التأمين تدفع للسائق الفرق في القيمة لأن السيارة تضررت بسبب حادث لم يكن هو المسؤول عنه، وعند بيع السيارة سيحصل على مبلغ أقل من قيمتها بسبب ذلك.",
      "Rules-menu": "القواعد",
      "Rules-title": "قواعد شراء سيارة مستعملة",
      "Rules-subtitle": "ماذا يجب عليك القيام به قبل شراء سيارة مستعملة",
      "Rules-label-1": "التحدث مع المالك/السائق",
      "Rules-info-1": "في هذه المرحلة، من المهم جدًا طلب صور حديثة للسيارة والسؤال عن المسافة المقطوعة، الملاك السابقين، تاريخ الأعطال والصيانة.",
      "Rules-label-2": "افحص السيارة بالعكس",
      "Rules-info-2": "يمكنك من خلال العكس التعرف على السيارة التي تفكر في شرائها عن بُعد، قبل أن تبذل الجهد للسفر والتحقق من حالتها ومقابلة المالك.",
      "Rules-label-3": "افحص السيارة بالعكس",
      "Rules-info-3": "يمكنك من خلال العكس التعرف على السيارة التي تفكر في شرائها عن بُعد، قبل أن تبذل الجهد للسفر والتحقق من حالتها ومقابلة المالك.",
      "Rules-label-4": "اختبار القيادة والفحص الخارجي",
      "Rules-info-4": "افحص السيارة من الخارج، افتح غطاء المحرك للتحقق من الوصلات السائبة، التسربات، حالة البطارية، وخذ السيارة لاختبار القيادة. من المهم جدًا إيقاف الراديو، فتح النوافذ والاستماع إذا كان هناك أصوات غير عادية. تحقق من أن المكيف يعمل.",
      "Rules-label-5": "تحقق من التفاصيل",
      "Rules-info-5": "تأكد من أن التفاصيل متوافقة وأن المالك هو في الواقع صاحب السيارة وفقًا لسجل السيارة وهوية البائع.",
      "Rules-info-5-important-1": "اقرأ تاريخ السيارة — هل كان للسيارة حوادث مع انخفاض في قيمتها؟ وما هو آخر عداد كيلومترات تم الإبلاغ عنه؟ هذه هي اللحظة لتحديد ما إذا كانت الصفقة عادلة أم لا.",
      "Rules-info-5-important-2": "من المهم جدًا!",
      "Rules-label-6": "لم تشترِ؟ تقريرك له قيمة",
      "Rules-info-6": "إذا لم تناسبك السيارة، فهذا هو المكان لتحميل تقرير شراء السيارة (لأنك لن تستخدمه أبدًا).",
      "Rules-info-6-upload-info": "تحميل التقرير سيمنحك خصمًا بنسبة 25% على التقرير التالي.",
      "Rules-info-6-upload-button": "يمكنك تحميل الملف هنا.",
      "Rules-label-7": "اتفاقيات؟ لدينا قالب جاهز",
      "Rules-info-7-upload-info": "وثيقة الإفصاح واتفاقية ما بعد الفحص/تقرير نوايا.",
      "Rules-info-7-upload-button": "قم بتحميلها من الموقع ووقع.",
      "Rules-label-8": "نبدأ طريقًا جديدًا، حظًا سعيدًا!",
      "Car number": "رقم السيارة",
      "Choose Language": "اختر اللغة"
    }
  },
  fr: {
    translation: {
      "Home-Title": "Inspection du véhicule avant achat",
      "Home-Subtitle": "Accidents, hypothèques, changements de propriétaire",
      "Home-Sale": "Offre spéciale de lancement - seulement 79,90 ₪",
      "Home-Car number placeholder": "Entrez le numéro d'immatriculation",
      "Home-Check button": "Vérifiez",
      "Checking the seller vehicle": "Vérification de la propriété du véhicule",
      "Official mortgage check": "Vérification officielle des hypothèques",
      "Vehicle history from database": "Historique du véhicule depuis la base de données",
      "Impairment test and total loss": "Test de dépréciation et perte totale",
      "Maintenance costs and red flags": "Guide pour vérifier les voitures d'occasion",
      "Start with": "Commencez avec cela",
      "Start with info": "Revers vous ramène dans le passé du véhicule, recueille des informations et vous fournit l’historique du véhicule au bon moment. Avant d'acheter une voiture d'occasion, a-t-elle subi une perte totale ? Peut-être est-elle volée ou hypothéquée ? Quel est son historique de propriété ? Peut-être y a-t-il quelque chose dans son passé que l’on essaie de cacher. Tout ce que vous devez savoir – en 5 minutes, directement dans votre boîte mail.",
      "Check before seen": "Vérifiez la voiture avant de la voir",
      "Check before seen info": "Nos systèmes collectent les données de toutes les bases de données officielles. Vous n'avez qu'à cliquer et recevoir le résultat en quelques minutes, au moment où c'est pertinent pour l'achat.",
      "Vehicle CV": "CV du véhicule - Qu'est-ce que cela signifie",
      "Vehicle CV info": "C'est très simple : un document clair, lisible et précis qui est envoyé directement à votre adresse email, détaillant les données que les autorités connaissent sur la voiture d'occasion que vous envisagez d'acheter : accidents, hypothèques, perte de valeur, historique de propriété, réclamations d'assurance – tout.",
      "Check list": "Que vérifie-t-on ici ?",
      "Check list info": "Revers sur les précédents propriétaires | Revers sur les hypothèques | Revers sur l'historique du véhicule | Revers sur la perte de valeur et les pertes totales | Revers sur l'entretien, les avertissements et les rappels",
      "Guide": "Vidéo d'instruction",
      "How report looks": "Découvrez le rapport - À quoi cela ressemble-t-il ?",
      "Full name": "Nom complet",
      "Full name require": "Veuillez entrer votre nom complet !",
      "Phone number": "Numéro de téléphone",
      "Phone number require": "Veuillez entrer votre numéro de téléphone !",
      "Email address": "Adresse e-mail",
      "Email address require": "Veuillez entrer votre adresse e-mail !",
      "Send": "Envoyer",
      "The commandments": "Les commandements",
      "Report of stolen vehicle": "Signaler un véhicule volé",
      "Report of a car seller": "Signaler un vendeur de voiture",
      "Accessibility": "Accessibilité",
      "To cancel an order": "Pour annuler une commande",
      "Terms": "Conditions générales",
      "Privacy Policy": "Politique de confidentialité",
      "Contact us": "Contactez-nous chez Revers !",
      "Drawer title": "Paramètres",
      "User logged out": "Utilisateur déconnecté",
      "Log In": "Se connecter",
      "Logged as": "Connecté en tant que",
      "Logout": "Se déconnecter",
      "Login account": "Connexion au compte",
      "LOGIN": "CONNEXION",
      "OR REGISTER": "OU INSCRIVEZ-VOUS",
      "Something went wrong": "Une erreur s'est produite",
      "User logged in": "Utilisateur connecté",
      "Email address is not valid": "Adresse e-mail invalide",
      "Password require": "Veuillez entrer votre mot de passe !",
      "Password": "Mot de passe",
      "Reset password": "Réinitialiser le mot de passe",
      "Remember me": "Se souvenir de moi",
      "Password conditions": "Minimum 8 caractères, au moins 1 lettre et 1 chiffre",
      "Confirm password": "Confirmer le mot de passe",
      "Confirm password require": "Veuillez entrer votre mot de passe de confirmation !",
      "Passwords do not match": "Les mots de passe ne correspondent pas",
      "SIGNUP": "INSCRIPTION",
      "BACK": "RETOUR",
      "Reset password - Alert description": "Une demande de réinitialisation de votre mot de passe a été envoyée à votre adresse e-mail. Veuillez vérifier votre boîte de réception pour poursuivre le processus.",
      "Show report": "Afficher le rapport",
      "Show reports history": "Afficher l'historique des rapports",
      "Created date": "Date de création",
      "File": "Fichier",
      "Main page": "Page principale",
      "Email sent successfully": "E-mail envoyé avec succès",
      "Email sent description": "Nous avons reçu votre demande et nous vous contacterons sous peu.",
      "Owner ID": "Numéro d'identification du propriétaire principal du véhicule",
      "Owner date": "Date de début de propriété du véhicule (selon la carte grise)",
      "Report preparation alert": "Merci, votre rapport est en cours de préparation et peut prendre quelques minutes. Une fois prêt, vous pourrez le consulter sur votre page de rapports.",
      "Search another car": "Rechercher une autre voiture",
      "Report demo": "À quoi ressemble le rapport",
      "Order report": "Commander un rapport",
      "Why-menu": "Pourquoi ?",
      "Why-title": "Pourquoi choisir Revers ?",
      "Why-subtitle": "Les informations fournies par Revers peuvent vous éviter l'achat inutile d'une voiture défectueuse, mécaniquement ou légalement. Vous voulez conduire l'esprit tranquille ? Choisissez Revers.",
      "Why-label-1": "Vérification d'un véhicule saisi",
      "Why-info-1": "Un débiteur qui fait tout ce qu'il peut pour récupérer son argent ouvre un dossier auprès de l'exécution forcée et, en conséquence, une saisie est enregistrée auprès du Ministère des Transports pour le véhicule avec un numéro d'immatriculation spécifique. Toute personne souhaitant effectuer un transfert de propriété électronique dans une agence de la poste, mais découvrant que le véhicule est saisi, ne pourra pas effectuer le transfert de propriété.",
      "Why-label-2": "Comment résoudre le problème ?",
      "Why-info-2": "Si l'acheteur souhaite cette voiture, il existe deux options de solution, mais cela ne fonctionne que si le propriétaire actuel de la voiture reconnaît la dette et comprend qu'il doit la payer.",
      "Why-info-2-checkmark-1": "L'acheteur paiera la dette et ainsi la saisie sera levée sur le véhicule. Ensuite, il paiera au vendeur le solde restant du prix de la voiture convenu, après avoir déduit la valeur de la dette payée.",
      "Why-info-2-checkmark-2": "Si le vendeur paie lui-même la dette et prouve que la saisie a été levée, il sera alors possible d'effectuer le transfert de propriété électronique.",
      "Why-label-3": "Véhicule hypothéqué",
      "Why-info-3": "Si le propriétaire du véhicule a obtenu un financement pour l'achat de la voiture, il hypothèque la voiture en garantie du remboursement du prêt. Avant d'acheter, il est important de vérifier qu'il n'y a pas d'hypothèque sur la voiture. Reverse effectue cette vérification pour vous.",
      "Why-label-4": "Véhicule inscrit au registre des gages",
      "Why-info-4": "Une autre manière d'éviter le transfert de propriété est d'enregistrer les informations d'un véhicule spécifique dans le registre des gages, empêchant ainsi sa vente à une autre personne.",
      "Why-label-5": "La méthode simple pour éviter les problèmes",
      "Why-info-5": "Un petit contrôle peut vous éviter bien des tracas. Utilisez Reverse pour découvrir tout ce que le vendeur ne vous a pas dit sur le véhicule que vous envisagez d'acheter.",
      "Handicapped-menu": "Carte de handicap",
      "Handicapped-title": "Carte de handicap : à vérifier avant d'acheter",
      "Handicapped-subtitle": "Vous ne voulez sûrement pas vous retrouver dans une situation où vous ne pouvez pas transférer la propriété, n'est-ce pas ? Pour éviter des complications inutiles, assurez-vous d'annuler la carte de handicap (si elle existe) avant de vendre la voiture. Voici tout ce que vous devez savoir pour le faire correctement.",
      "Handicapped-label-1": "Qu'est-ce qu'une carte de handicap ?",
      "Handicapped-info-1": "Une carte de handicap est un permis de stationner dans des places réservées aux personnes handicapées – elle est délivrée par les autorités officielles d'Israël. Un propriétaire de véhicule qui remplit les critères et reçoit un certificat de handicap du ministère des Transports reçoit une carte de handicap qu'il colle sur le pare-brise de son véhicule, et cette information est enregistrée dans les registres du ministère des Transports.",
      "Handicapped-label-2": "Vous achetez une voiture ? N'oubliez pas de vérifier la carte de handicap",
      "Handicapped-info-2": "Une des premières choses à vérifier lorsque vous achetez une voiture d'occasion est de savoir si elle est associée à une carte de handicap, permettant de se garer dans des places spéciales. Celui qui achète une voiture d'occasion et continue de la posséder (même sans le savoir) pourrait rencontrer des problèmes.",
      "Handicapped-label-3": "Que faire au ministère des Transports ?",
      "Handicapped-info-3": "En raison des avantages liés au stationnement pour les personnes handicapées, lorsque vous vendez une voiture avec une carte de handicap, le ministère des Transports ne permet pas de transférer ce droit au nouveau propriétaire car il n'est pas reconnu comme handicapé par le ministère. Par conséquent, avant de transférer la propriété, le propriétaire actuel doit retourner la carte de handicap au ministère des Transports pour que le statut de ce numéro d'immatriculation soit celui d'un véhicule normal, sans privilèges. Ce n'est qu'après cette étape que le transfert de propriété peut être effectué.",
      "Handicapped-label-4": "Vous achetez une voiture ? Vérifiez à l'avance la carte de handicap",
      "Handicapped-info-4": "Si quelqu'un achète une voiture et ne sait pas qu'elle possède une carte de handicap, il se heurtera à un refus lors du transfert de propriété en raison de cette carte. Dans ce cas, il devra demander au propriétaire actuel de faire ce qu'il faut avant de pouvoir procéder au changement nécessaire.",
      "Curved vehicle-menu": "Véhicule saisi ou hypothéqué",
      "Curved vehicle-title": "Véhicule saisi ou hypothéqué : comment éviter l'achat ?",
      "Curved vehicle-subtitle": "Que faire pour éviter de se retrouver à acheter une voiture et découvrir que le propriétaire n'est pas vraiment le propriétaire ? Vous avez deviné : utilisez Reverse.",
      "Curved vehicle-label-1": "L'aspect juridique de votre futur véhicule",
      "Curved vehicle-info-1": "Toute personne souhaitant acheter une voiture d'occasion doit vérifier son état mécanique, électrique ainsi que son aspect juridique. Il existe des cas où la voiture est saisie ou hypothéquée, il faut donc être vigilant pour éviter les problèmes.",
      "Curved vehicle-label-2": "Reverse résout le problème",
      "Curved vehicle-info-2": "Les informations que vous obtenez sur l'historique de vie du véhicule via Reverse vous permettent de savoir si le véhicule est saisi ou hypothéqué, ainsi que d'autres informations importantes à vérifier.",
      "Curved vehicle-label-3": "Transfert de propriété du véhicule",
      "Curved vehicle-info-3": "Vous avez trouvé une voiture qui répond à vos besoins ? Super. Maintenant, il est important de vérifier que son statut juridique est en ordre pour conclure l'affaire.",
      "Curved vehicle-label-4": "Hypothèque de véhicule",
      "Curved vehicle-info-4": "Une personne qui doit de l'argent à diverses parties, comme des fournisseurs, des autorités fiscales ou d'autres entités, et qui craint de ne pas pouvoir honorer ses engagements financiers, se voit ouvrir un dossier d'exécution forcée. Cela permet de saisir de l'argent, des biens ou des véhicules appartenant à cette personne dans le but de les vendre, ou de vendre une partie d'entre eux, pour obtenir le paiement qui revient à cette partie.",
      "Curved vehicle-label-5": "Hypothèque de voiture",
      "Curved vehicle-info-5": "Certaines entités effectuent des hypothèques sur des véhicules. Grâce à Reverse, vous pouvez savoir si un véhicule est hypothéqué et auprès de qui.",
      "Recall-menu": "Ordre de rappel",
      "Recall-title": "Ordre de rappel ? Il y a une pièce défectueuse !",
      "Recall-subtitle": "Un rappel est une situation où un fabricant de véhicules annonce qu'il y a une pièce défectueuse dans la production et qu'elle doit être remplacée dans toutes les voitures. Lors de l'achat d'une voiture d'occasion, il est important de vérifier si la voiture que vous achetez a subi un rappel si nécessaire.",
      "Recall-label-1": "Lorsque le fabricant découvre un défaut de fabrication et que les voitures sont déjà sur la route",
      "Recall-info-1": "Les fabricants de véhicules dans le monde entier accordent une grande importance à la sécurité des véhicules, ils suivent donc tous les processus de réparation et de vérification. Cela commence dès que la voiture est vendue, car les véhicules passent des entretiens et des vérifications régulières dans les garages agréés selon les instructions du fabricant.",
      "Recall-label-2": "Appel de rappel pour les véhicules",
      "Recall-info-2": "S'il y a un défaut unique ou un problème récurrent, les ingénieurs automobile examinent cela en profondeur et arrivent souvent à la conclusion qu'il y a un problème technique avec certaines pièces qui doivent être remplacées pour protéger les conducteurs et les passagers et garantir leur sécurité. Le fabricant fabrique ensuite des pièces de remplacement plus adaptées que la pièce d'origine. L'étape suivante consiste à appeler tous les propriétaires de véhicules du modèle spécifique selon l'année de fabrication pour se rendre dans un garage agréé afin de procéder au remplacement. Toute personne ayant acheté ce modèle de voiture reçoit une notification via divers moyens de communication et prend rendez-vous dans un garage agréé pour effectuer le remplacement.",
      "Recall-label-3": "Engagement à réaliser le rappel",
      "Recall-info-3": "En raison de l'importance de la mise en œuvre du rappel afin d'éviter des situations dangereuses, tout propriétaire de véhicule est tenu de procéder à cette action dès que possible.",
      "Recall-label-4": "Rappel, condition nécessaire pour passer le contrôle technique",
      "Recall-info-4": "Chaque année, chaque véhicule subit un contrôle technique annuel pour vérifier son bon état. Si le propriétaire du véhicule se présente au centre de contrôle technique et qu'il est constaté qu'il n'a pas effectué l'échange de la pièce spécifique conformément aux instructions du fabricant, il ne pourra pas réussir le contrôle technique annuel. Après avoir effectué l'action requise et si tout est en ordre, il pourra réussir le contrôle technique et continuer à conduire.",
      "Certified garage-menu": "Garage agréé",
      "Certified garage-title": "Garage agréé : Pourquoi est-il nécessaire ?",
      "Certified garage-subtitle": "Le ministère des Transports accorde une licence et une supervision pour nous, les conducteurs, afin de garantir que nos véhicules sont entre de bonnes mains.",
      "Certified garage-label-1": "Qu'est-ce qu'un garage agréé ?",
      "Certified garage-info-1": "Les garages agréés par le ministère des Transports sont des garages sous supervision directe du ministère et qui sont généralement membres de l'association des garages.",
      "Certified garage-label-2": "Quels sont les standards auxquels les garages agréés doivent se conformer ?",
      "Certified garage-info-2": "Dans un garage agréé, le ministère des Transports fournit un service professionnel pour les véhicules de toutes sortes et de tous les fabricants. Ces garages doivent respecter des règlements stricts. Les employés du garage reçoivent une formation spécialisée sur la manière de vérifier, réparer et entretenir les véhicules des différents fabricants. Le chef du garage doit être un mécanicien qualifié avec une formation spécifique qui dirige le processus professionnel.",
      "Certified garage-label-3": "Transparence : Comment fonctionne un garage agréé ?",
      "Certified garage-info-3": "Dans ce type de garage, une liste de prix doit être affichée dans un endroit visible. Cette liste détaille le tarif horaire et le coût des pièces d'origine. Après avoir reçu le véhicule pour entretien, les employés du garage doivent informer le client des résultats de l'inspection, des réparations nécessaires et de leurs coûts. Le travail commence uniquement après l'approbation du client.",
      "Certified garage-label-4": "Documentation des actions dans le garage agréé",
      "Certified garage-info-4": "Dans un garage agréé, toutes les données relatives aux réparations effectuées sur le véhicule, les pièces remplacées, les entretiens effectués et toute autre information sont enregistrées dans un logiciel informatique. Cela permet au propriétaire de vérifier, par exemple, quand les freins ont été remplacés, la courroie de distribution, etc. Ces informations sont importantes pour chaque propriétaire de véhicule, en particulier lors de l'achat d'une voiture d'occasion.",
      "Certified garage-label-5": "Garages de rue – Comment les identifier et s'en protéger ?",
      "Certified garage-info-5": "Les garages de rue sont des garages non agréés qui fonctionnent sans supervision. Par conséquent, de nombreux conducteurs ne sont pas satisfaits des réparations effectuées sur leurs véhicules car ils n'ont personne à contacter en cas de problème et ils ne sont pas supervisés par le ministère des Transports. Les prix dans ces garages sont souvent plus élevés et les pièces utilisées ne sont pas toujours d'origine ou neuves. Parfois, des pièces d'occasion sont installées, ce qui peut entraîner des problèmes de véhicule à court terme. Il est recommandé de ne pas faire réparer son véhicule dans ces garages.",
      "Car dealer-menu": "Concessionnaire automobile agréé",
      "Car dealer-title": "Concessionnaire automobile agréé : Pourquoi est-il important ?",
      "Car dealer-subtitle": "Vous voulez acheter une voiture sans risquer une mauvaise affaire ? Achetez chez un concessionnaire agréé par le ministère des Transports et conduisez en toute tranquillité.",
      "Car dealer-label-1": "Qu'est-ce qu'un concessionnaire automobile agréé ?",
      "Car dealer-info-1": "Un concessionnaire automobile agréé est un professionnel qui a reçu l'autorisation de la part du ministère des Transports pour exercer son activité dans le domaine de la vente de véhicules, et donc son activité est régulée.",
      "Car dealer-label-2": "Avantages d'acheter chez un concessionnaire agréé",
      "Car dealer-info-2-checkmark-1": "Achat dans un lieu fiable",
      "Car dealer-info-2-checkmark-2": "Achat dans un endroit qui se soucie de sa réputation",
      "Car dealer-info-2-checkmark-3": "Achat auprès d'un professionnel qui connaît les caractéristiques des véhicules et en est responsable",
      "Car dealer-info-2-checkmark-4": "Obtention d'un véhicule après révision et amélioration",
      "Car dealer-label-3": "Ils réparent et vendent les véhicules",
      "Car dealer-info-3": "L'objectif de chaque concessionnaire est d'acheter des véhicules à un prix inférieur à leur valeur marchande, puis de les envoyer pour réparation auprès de professionnels avec lesquels ils collaborent, tels que des électriciens automobile, des mécaniciens, des carrossiers, des peintres, etc., en fonction de l'état du véhicule. Après les réparations nécessaires comme la peinture et la carrosserie, le véhicule est soigneusement nettoyé à l'intérieur et à l'extérieur, puis mis en vente dans les médias appropriés.",
      "Car dealer-label-4": "Achat de véhicules d'occasion",
      "Car dealer-info-4": "Les concessionnaires achètent des véhicules lors de ventes aux enchères, dans des terrains de voitures d'échange et auprès de particuliers. Ils détiennent un certificat de commerçant qui leur permet de réparer le véhicule jusqu'à sa vente sans transfert de propriété, ce qui signifie qu'aucun nouveau propriétaire n'est ajouté dans les registres. Ce certificat permet également de geler la date du contrôle technique lors de la vente du véhicule, le nouveau propriétaire étant responsable du paiement du contrôle technique annuel.",
      "How it works-menu": "Comment ça marche ?",
      "How it works-title": "Comment ça fonctionne ?",
      "How it works-subtitle": "Trois étapes et vous avez l'historique du véhicule avec des données et des perspectives pour prendre la route.",
      "How it works-label-1": "Première étape : Retour sur 7 ans",
      "How it works-info-1": "Nous vous équipons d'informations sur les points les plus sensibles lors de l'achat d'un véhicule d'occasion.",
      "How it works-info-1-checkmark-1": "A-t-elle été impliquée dans un accident ?",
      "How it works-info-1-checkmark-2": "La valeur a-t-elle chuté à cause d'accidents ?",
      "How it works-info-1-checkmark-3": "La voiture a-t-elle été volée ?",
      "How it works-info-1-checkmark-4": "La voiture était-elle assurée ?",
      "How it works-info-1-checkmark-5": "La voiture a-t-elle été déclarée perte totale ?",
      "How it works-info-1-checkmark-6": "Des indemnités ont-elles été versées par les compagnies d'assurance ?",
      "How it works-label-2": "Étape 2 : Propriétaires précédents",
      "How it works-info-2": "Celui qui vend une voiture d’occasion de troisième main peut ne pas savoir tout sur l’historique de la voiture.",
      "How it works-info-2-checkmark-1": "La voiture a-t-elle été auparavant possédée par un acteur commercial ?",
      "How it works-info-2-checkmark-2": "Y a-t-il une raison de douter de l'un des propriétaires précédents ?",
      "How it works-label-3": "Étape 3 : Conclusions et outils pour une transaction correcte",
      "How it works-info-3": "Nous vous fournissons des informations sur les points sensibles lors de l'achat d'une voiture d'occasion.",
      "How it works-info-3-checkmark-1": "Y a-t-il des données préoccupantes auxquelles il faut prêter attention ?",
      "How it works-info-3-checkmark-2": "Cette voiture semble-t-elle être une bonne opportunité ?",
      "How it works-info-3-checkmark-3": "Que dit notre intelligence artificielle sur cette opportunité ?",
      "How it works-info-4": "Le système d'intelligence artificielle vous aide en analysant avec vous : quelle est la meilleure action à prendre maintenant ?",
      "How it works-info-4-checkmark-1": "Combien devrais-je payer ?",
      "How it works-info-4-checkmark-2": "Perte de valeur — Que dit notre prévision ?",
      "How it works-info-4-checkmark-3": "Combien va coûter l'entretien de cette voiture ?",
      "How it works-info-4-checkmark-4": "Formulaires de vente",
      "How it works-info-4-checkmark-5": "Instructions et conseils : comment procéder à l'achat ou à la vente",
      "Total loss-menu": "Pertes totales",
      "Total loss-title": "Pertes totales ? Comment ne pas acheter une telle voiture",
      "Total loss-subtitle": "Une perte totale est une situation dans laquelle une voiture est retirée de la route après qu'un dommage causé par un accident soit égal ou supérieur à 60 % de sa valeur. Quel est le processus derrière la déclaration de ce type de voiture ? Toutes les informations sont ici.",
      "Total loss-label-1": "Expert automobile – Le professionnel pour ces situations",
      "Total loss-info-1": "Une carte de stationnement pour personnes handicapées est une licence permettant de se garer dans des zones réservées uniquement aux personnes handicapées, délivrée par les autorités officielles d'Israël. Le propriétaire d'un véhicule qui répond aux critères et qui reçoit un certificat du ministère des Transports obtient cette carte, facilitant ainsi sa vie quotidienne.",
      "Total loss-label-2": "Perte totale – Qu'est-ce que c'est ?",
      "Total loss-info-2": "Une perte totale est une situation où il n'est pas économiquement viable de réparer la voiture, donc l'expert la retire de la circulation. Cette décision a plusieurs conséquences.",
      "Total loss-info-2-checkmark-1": "Comme la voiture ne peut généralement pas circuler sur la route, elle est remorquée vers un centre de récupération pour être démontée. Certaines pièces sont recyclées et les pièces en bon état sont revendues comme pièces détachées d'occasion.",
      "Total loss-info-2-checkmark-2": "Le conducteur doit informer le ministère des Transports que la voiture a été démontée et doit être retirée du registre des véhicules en circulation.",
      "Total loss-info-2-checkmark-3": "L'assurance paie au propriétaire la valeur complète de la voiture, selon l'évaluation de l'expert.",
      "Total loss-label-3": "Perte partielle de valeur de la voiture",
      "Total loss-info-3": "Après un accident relativement léger, l'expert approuve des réparations spécifiques effectuées par l'atelier de réparation, qui sont payées par l'assurance du conducteur responsable. Cependant, ces réparations entraînent une réduction de la valeur de la voiture de certains pourcentages. Chaque partie du véhicule a un pourcentage, par exemple :",
      "Total loss-info-3-example-1": "Aile arrière – X%",
      "Total loss-info-3-example-2": "Châssis – Y%",
      "Total loss-info-3-example-3": "Carrosserie endommagée – Z%",
      "Total loss-label-4": "L'assurance est toujours dans l'image",
      "Total loss-info-4": "L'assurance indemnise le propriétaire de la voiture pour la perte de valeur car elle a été endommagée lors d'un accident dont il n'était pas responsable. Lors de la vente de la voiture, il obtiendra un montant inférieur à sa valeur en raison de cela.",
      "Rules-menu": "Règles",
      "Rules-title": "Règles pour acheter une voiture d'occasion",
      "Rules-subtitle": "Que devez-vous faire avant d'acheter une voiture d'occasion",
      "Rules-label-1": "Parlez au propriétaire / conducteur",
      "Rules-info-1": "À ce stade, il est très important de demander des photos récentes de la voiture et de poser des questions sur le kilométrage, les propriétaires précédents, l'historique des pannes et des réparations.",
      "Rules-label-2": "Vérifiez la voiture en marche arrière",
      "Rules-info-2": "Avec la marche arrière, vous pouvez déjà découvrir la voiture que vous envisagez d'acheter à distance, avant même de faire l'effort de vous rendre sur place pour vérifier son état et rencontrer le propriétaire.",
      "Rules-label-3": "Vérifiez la voiture en marche arrière",
      "Rules-info-3": "Avec la marche arrière, vous pouvez déjà découvrir la voiture que vous envisagez d'acheter à distance, avant même de faire l'effort de vous rendre sur place pour vérifier son état et rencontrer le propriétaire.",
      "Rules-label-4": "Essai de conduite et vérification visuelle extérieure",
      "Rules-info-4": "Examinez l'extérieur de la voiture, ouvrez le capot pour rechercher des connexions lâches, des fuites, l'état de la batterie et faites un essai de conduite. Il est très important d'éteindre la radio, d'ouvrir les fenêtres et d'écouter si des bruits anormaux se produisent. Vérifiez que la climatisation fonctionne.",
      "Rules-label-5": "Vérification des informations",
      "Rules-info-5": "Assurez-vous que les informations correspondent et que le propriétaire est bien le titulaire du véhicule selon le certificat d'immatriculation et la carte d'identité du vendeur.",
      "Rules-info-5-important-1": "Lisez l'historique du véhicule — la voiture a-t-elle eu des accidents avec une perte de valeur ? Quelle est la dernière valeur du kilométrage enregistrée ? C'est le moment de décider si l'accord est juste ou non.",
      "Rules-info-5-important-2": "Très important !",
      "Rules-label-6": "Vous n'avez pas acheté ? Votre rapport vaut de l'argent",
      "Rules-info-6": "Si la voiture ne vous convient pas, c'est ici que vous téléchargez le rapport d'achat (car vous ne l'utiliserez de toute façon pas).",
      "Rules-info-6-upload-info": "Télécharger le rapport vous offrira une réduction de 25 % sur le prochain rapport.",
      "Rules-info-6-upload-button": "Vous pouvez télécharger le fichier ici.",
      "Rules-label-7": "Des accords ? Nous avons déjà un modèle prêt",
      "Rules-info-7-upload-info": "Document de divulgation et accord après examen/attestation de l'accord.",
      "Rules-info-7-upload-button": "Téléchargez-le depuis le site et signez.",
      "Rules-label-8": "Nous partons pour un nouveau départ, bonne chance !",
      "Car number": "Numéro de voiture",
      "Choose Language": "Choisir la langue"
    }
  }
};

export default translations;
